import React from 'react';
// components
import Divider from "@mui/material/Divider";
import { Button, Card, CardHeader, CardContent } from '@mui/material';
import Swal from 'sweetalert2';

import Page from '../../componentes/Page';
import Iconify from '../../componentes/Iconify';
import CONFIG from '../../CONFIG.json';
import { buildBody, isEmpty, getUniqueKey, workWithLocalStorage, compruebaForm } from '../../auxiliares/funciones';
import { getProvincias, getAsesorias } from '../../auxiliares/maestros';
import EmpForm from './form/empresaform';
import Trabajadores from '../trabajadores';

// ----------------------------------------------------------------------
export default function AltaForm(props) {
    const usuario = workWithLocalStorage('get','usuario',null);
    const Form = {
        idAsesoria:0,
        nombre: '',
        cif: '',
        telefono: '',
        email: '',
        representanteLegal: '',
        cifRepresentante: '',
        conceptoRepresentante: '',
        sexoRepresentante: '',
        direccion: '',
        cp: '',
        localidad: '',
        idProvincia: 0,
        direccionCentroTrabajo: '',
        centrosComunidad: 0,
        cnae: '',
        cuentaCotizacion: '',
        cuentaBancaria: '',
        bic:'',
        numTrabajadores: 0,
        reduccionCuotaMinima: 0,
        bonificacionTutor: 0,
        activo: 1,
      };
      const obligatorios = ['idAsesoria','nombre','cif','telefono','email'];

    const [empresa, setEmpresa] = React.useState(Form);
    const [cargado, setCargado] = React.useState(false);
    const [provincias, setProvincias] = React.useState([]);
    const [asesorias, setAsesorias] = React.useState([]);
    const [id,setId] = React.useState(props.params.id ? props.params.id : '0');

    React.useEffect(() => {
        async function cargaInicial(){
            if(!cargado){         
                setProvincias(await getProvincias());
                setAsesorias(await getAsesorias());
                
                if(id !== '0'){                
                    // eslint-disable-next-line
                    const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,{ id:id }))
                    fetch(`${CONFIG.api}empresa/detalle`,{
                                method: 'POST',
                                body: bodyJson,
                                headers: {'Content-Type': 'application/json'}
                        })
                        .then(res => res.json())
                        .then((result) => {
                        if (result.error === 0 && !isEmpty(result.data)) {
                            setEmpresa(result.data);
                        }
                        props.showLoader(false);            
                        setCargado(true);
                    })
                }else{
                    setCargado(true);
                }
            }
        }
        cargaInicial();
      // eslint-disable-next-line
      },[cargado]);

    const Grabar = () =>{
        const valido = compruebaForm(Form,empresa,obligatorios);
        if(valido){
            // eslint-disable-next-line react/prop-types
            props.showLoader(true); 
            const body = empresa;
            body.idUsuarioAlta = usuario.id;
            body.idUsuarioMod = usuario.id;
            const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
            fetch(`${CONFIG.api}empresa/detalle`,{
                    method: 'PUT',
                    body: bodyJson,
                    headers: {'Content-Type': 'application/json'}
            })
            .then(res => res.json())
            .then((result) => {
                let msg ='Empresa grabada!';
                if (result.error === 0) {   
                    setId(result.data.id);
                    document.getElementById(`simple-tab-${props.index}`).getElementsByTagName( 'span' )[0].textContent = `Editar Empresa #${result.data.id} `;
                    setCargado(false);
                }else{
                    props.showLoader(false); 
                    msg = result.errorMessage;
                }
                Swal.fire({
                title: msg,
                buttons: [{label: 'Ok'}]
                })
            })
        }else{
            Swal.fire({
                title: 'Revisa los campos con *',
                buttons: [{label: 'Ok'}]
            })
        }
    }

    const Nuevo = () =>{
        setEmpresa(Form);
        setId('0')
        document.getElementById(`simple-tab-${props.index}`).getElementsByTagName( 'span' )[0].textContent = 'Alta Empresa ';
    }
    
    const actualizaCampo = (input) =>{
        const nombre = input.target.name
        const valor = input.target.value
        const empresaA = empresa;
        empresaA[nombre] = valor
        setEmpresa(empresaA);
    }

    const Activar = () =>{
        // eslint-disable-next-line react/prop-types
        props.showLoader(true); 
        const body = {
          tabla: "empresas",
          activo: 1,
          usuario: usuario.id,
          id: empresa.id
        }
        const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
        fetch(`${CONFIG.api}master/activo`,{
                  method: 'PUT',
                  body: bodyJson,
                  headers: {'Content-Type': 'application/json'}
          })
          .then(res => res.json())
          .then((result) => {
            let msg ='Empresa activada!';
            if (result.error === 0) {   
                setCargado(false);
            }else{
              props.showLoader(false); 
              msg = result.errorMessage;
            }
            Swal.fire({
              title: msg,
              buttons: [{label: 'Ok'}]
            })
        })
    }

    const Borrar = () =>{
        // eslint-disable-next-line react/prop-types
        props.showLoader(true); 
        const body = {
            tabla: "empresas",
            activo: 0,
            usuario: usuario.id,
            id: empresa.id
        }
        const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
        fetch(`${CONFIG.api}master/activo`,{
                    method: 'PUT',
                    body: bodyJson,
                    headers: {'Content-Type': 'application/json'}
            })
            .then(res => res.json())
            .then((result) => {
                let msg ='Empresa desactivada!';
                if (result.error === 0) {   
                    setCargado(false);
                }else{
                    props.showLoader(false); 
                    msg = result.errorMessage;
                }
                props.showLoader(false); 
                Swal.fire({
                title: msg,
                buttons: [{label: 'Ok'}]
                })
        })
    }
    
    return (
        <Page title="Alta Empresa" key={getUniqueKey()}>
            <Card  sx={{ maxWidth: '100% !important'}}>
                <CardHeader action={<>
                                    <Button variant="contained" color="success" sx={{ color: 'white', marginRight: '15px'}} to="#" startIcon={<Iconify icon="eva:plus-fill" />}
                                    onClick={()=>Grabar(null)}>
                                        Grabar
                                    </Button>
                                    <Button variant="contained" color="primary" sx={{ color: 'white', marginRight: '15px'}} to="#" startIcon={<Iconify icon="eva:edit-outline" />}
                                    onClick={()=>Nuevo()}>
                                        Nuevo
                                    </Button>
                                    {empresa.id &&
                                    (empresa.activo === 1 ?
                                    <Button variant="contained" color="error" sx={{ color: 'white'}} to="#" startIcon={<Iconify icon="eva:trash-2-outline" />}
                                    onClick={()=>Borrar()}>
                                        Borrar
                                    </Button>
                                    : <Button variant="contained" color="warning" sx={{ color: 'white'}} to="#" startIcon={<Iconify icon="eva:refresh-fill" />}
                                    onClick={()=>Activar()}>
                                        Activar
                                    </Button>)
                                    }
                                    </>}
                        title={id === '0' ? "Alta Empresa" : `Editar Empresa #${id}`}
                />
                <Divider style={{ margin: '15px'}} />
                <CardContent>
                    {cargado &&
                    <EmpForm showLoader={props.showLoader} provincias={provincias} asesorias={asesorias} empresa={empresa} actualizaCampo={actualizaCampo}/>
                    }
                </CardContent>
                {empresa.id && <Divider style={{ margin: '15px'}} />}
                {empresa.id && 
                <CardContent>
                    <Trabajadores idEmpresa={id} showLoader={props.showLoader} setTabs={props.setTabs}/>
                </CardContent>
                }
            </Card>
        </Page>
    );
}
