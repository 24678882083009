import React from 'react';
// @mui
import { Grid, Button } from '@mui/material';
import Swal from 'sweetalert2';
// components
import Iconify from '../../../componentes/Iconify';

import { buildBody, isEmpty, workWithLocalStorage } from '../../../auxiliares/funciones';
import { getPlanificacion } from '../../../auxiliares/clases/contratos';
import RLabel from '../../../componentes/forms/inputs/RLabel';
import CONFIG from '../../../CONFIG.json';
import RTabla from '../../../componentes/tabla';

// ----------------------------------------------------------------------

export default function PlanForm({ showLoader, contrato }) {   

  const [plan, setPlan] = React.useState([]);
  const [cargado, setCargado] = React.useState(false);
  const [horas, setHoras] = React.useState(0);
  const usuario = workWithLocalStorage('get','usuario',null);  

  React.useEffect(() => {
    async function cargaInicial(){
        if(!cargado){
            // console.log(contrato);
          const planis = await getPlanificacion(contrato.id);
            if(planis.length > 0){
              let horasT = 0;
              for(let i = 0; i < planis.length; i += 1){
                  if(contrato.estadocontrato === planis[i].estado) {
                      horasT += planis[i].horas;
                  }
              } 
              /* const fechaI = new Date(contrato.fechaIE !== '0000-00-00' ? contrato.fechaIE : contrato.fechaInicio);
              const fechaF = new Date(contrato.fechaFE !== '0000-00-00' ? contrato.fechaFE : contrato.fechaFin);
              let semanasF = getWeeksBetween(fechaI,fechaF);
              let horasFormS = 0;
              if(semanasF>52){
                horasFormS = roundHalf((contrato.horasLaborales*0.35)*52);
                semanasF -= 52;
                horasFormS += roundHalf((contrato.horasLaborales*0.15)*semanasF);
              }else{
                horasFormS = roundHalf((contrato.horasLaborales*0.35)*semanasF);
              }
              const horasCon = contrato.horasConvenio*0.35;
              if(horasFormS>horasCon){
                horasFormS = horasCon;
              }
              // horasFormS -= horasT; */
              setHoras(horasT);
            }
            
              showLoader(false); 
              setCargado(true);
              setPlan(planis);
        }
    }
    cargaInicial();
  // eslint-disable-next-line
  },[cargado]);

  const CalculaPlanificacion = () =>{
    // eslint-disable-next-line react/prop-types
    showLoader(true); 
    const body = { id : contrato.id, usuario: usuario.id};

    const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
    fetch(`${CONFIG.api}contrato/planificacion/calcular`,{
            method: 'POST',
            body: bodyJson,
            headers: {'Content-Type': 'application/json'}
    })
    .then(res => res.json())
    .then((result) => { 
        if (result.error === 0) {  
              setCargado(false); 
              Swal.fire({
                title: 'Planificacion calculada!',
                text: `Horas usadas: ${result.data.horas}`,
                buttons: [{label: 'Ok'}]
              })
        }else{
          Swal.fire({
            title: result.errorMessage,
            buttons: [{label: 'Ok'}]
          })
          showLoader(false); 
        }
    })
}


const GenerarAnexo = () =>{
  // eslint-disable-next-line react/prop-types
  showLoader(true); 
  const body = { id : contrato.id, usuario: usuario.id};

  const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
  fetch(`${CONFIG.api}docs/anexo`,{
          method: 'POST',
          body: bodyJson,
          headers: {'Content-Type': 'application/json'}
  })
  .then(res => res.json())
  .then((result) => {
      // console.log(result);
      if (result.error === 0) {   
          // setCargado(false);    
          Swal.fire({
            title: 'Anexo generado!',
            buttons: [{label: 'Ok'}]
          }) 
          showLoader(false);      
      }else{
        Swal.fire({
          title: result.errorMessage,
          buttons: [{label: 'Ok'}]
        })
        showLoader(false); 
      }
  })
}

  /* ***************** TABLA ************************** */
  const campos = ['estado','fechaInicio','fechaFin','codigo','nombre'];    
  const cabecera = ['Estado','Desde','Hasta','Código','Curso'];

  return (
    <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <Grid item md={4}>
          <Button variant="contained" color="warning" sx={{ color: 'white', marginRight: '15px'}} to="#" startIcon={<Iconify icon="eva:calendar-outline" />}
          onClick={()=>CalculaPlanificacion()}> Calcular Planificación </Button>
          { contrato.planificacionHecha === 1 &&
          <Button variant="contained" color="primary" sx={{ color: 'white', marginRight: '15px'}} to="#" startIcon={<Iconify icon="eva:file-text-outline" />}
          onClick={()=>GenerarAnexo()}> Generar Anexo </Button>
          }
        </Grid>     
        {horas !== 0 && <RLabel md={3} label={`Horas usadas: ${horas}`}/>}
        {!isEmpty(plan) &&
        <Grid item md={12}>
            <RTabla lista={plan} campos={campos} cabecera={cabecera} acciones={[]}/>
        </Grid>
        }
    </Grid>
  );
}
