import CONFIG from '../../CONFIG.json';
import { buildBody, isEmpty } from '../funciones';

export const getAusencias = async(id) => {
    const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,{id}))
    const res = await fetch(`${CONFIG.api}contrato/ausencias`,{
        method: 'POST',
        body: bodyJson,
        headers: {'Content-Type': 'application/json'}
    });
    const result = await res.json()
    if (result.error === 0 && !isEmpty(result.data)) {
        return result.data;
    }
    return [];
}

export const getEstados = async(id) => {
    const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,{id}))
    const res = await fetch(`${CONFIG.api}contrato/estados`,{
        method: 'POST',
        body: bodyJson,
        headers: {'Content-Type': 'application/json'}
    });
    const result = await res.json()
    if (result.error === 0 && !isEmpty(result.data)) {
        return result.data;
    }
    return [];
}

export const getDocumentos = async(id) => {
    const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,{id, carpeta:'contratos'}))
    const res = await fetch(`${CONFIG.api}docs/listado`,{
        method: 'POST',
        body: bodyJson,
        headers: {'Content-Type': 'application/json'}
    });
    const result = await res.json()
    if (result.error === 0 && !isEmpty(result.data)) {
        return result.data;
    } 
    return [];
}

export const getEspecialidades = async(id) => {
    const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,{id}))
    const res = await fetch(`${CONFIG.api}contrato/especialidades`,{
        method: 'POST',
        body: bodyJson,
        headers: {'Content-Type': 'application/json'}
    });
    const result = await res.json()
    if (result.error === 0 && !isEmpty(result.data)) {
        return result.data;
    } 
    return [];
}

export const getPlanificacion = async(id) => {
    const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,{id}))
    const res = await fetch(`${CONFIG.api}contrato/planificacion`,{
        method: 'POST',
        body: bodyJson,
        headers: {'Content-Type': 'application/json'}
    });
    const result = await res.json()
    if (result.error === 0 && !isEmpty(result.data)) {
        return result.data;
    } 
    return [];
}

export const getBonificacion = async(id) => {
    const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,{id}))
    const res = await fetch(`${CONFIG.api}contrato/bonificacion`,{
        method: 'POST',
        body: bodyJson,
        headers: {'Content-Type': 'application/json'}
    });
    const result = await res.json()
    if (result.error === 0 && !isEmpty(result.data)) {
        return result.data;
    } 
    return [];
}

export const getLiquidacion = async(id) => {
    const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,{id}))
    const res = await fetch(`${CONFIG.api}contrato/liquidacion`,{
        method: 'POST',
        body: bodyJson,
        headers: {'Content-Type': 'application/json'}
    });
    const result = await res.json()
    if (result.error === 0 && !isEmpty(result.data)) {
        return result.data;
    } 
    return [];
}

export const getLiquidacionDetalle = async(id) => {
    const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,{id}))
    const res = await fetch(`${CONFIG.api}contrato/liquidacion/detalle`,{
        method: 'POST',
        body: bodyJson,
        headers: {'Content-Type': 'application/json'}
    });
    const result = await res.json()
    if (result.error === 0 && !isEmpty(result.data)) {
        return result.data;
    } 
    return {};
}

export const getSeriesFact = async() => {
    const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,{}))
    const res = await fetch(`${CONFIG.api}master/seriesfact`,{
        method: 'POST',
        body: bodyJson,
        headers: {'Content-Type': 'application/json'}
    });
    const result = await res.json()
    if (result.error === 0 && !isEmpty(result.data)) {
        return result.data;
    } 
    return {};
}