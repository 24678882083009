// component
import Iconify from '../../componentes/Iconify';

import { workWithLocalStorage } from '../../auxiliares/funciones';

import Redes from '../../paginas/redes';
import Asesorias from '../../paginas/asesorias';
import Empresas from '../../paginas/empresas';
import Trabajadores from '../../paginas/trabajadores';
import Festivos from '../../paginas/maestros/festivos';
import SeriesFact from '../../paginas/maestros/seriesfact';
import Contratos from '../../paginas/contratos';
import AltaContrato from '../../paginas/contratos/alta';
import LiqPendientes from '../../paginas/liquidaciones/liqpendientes';
import LiqRedes from '../../paginas/liquidaciones/liqredes';
import LiqAsesorias from '../../paginas/liquidaciones/liqasesorias';
import Facturacion from '../../paginas/liquidaciones/facturacion';
import Matriculaciones from '../../paginas/tutores/matriculas';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const user = workWithLocalStorage('get','usuario',null);

const menuConfig = [
  {
    id: 1,
    title: 'Redes Com.',
    path: '#',
    perfil: [0],
    icon: getIcon('eva:people-fill'),
    component: Redes,
    params: {}
  },
  {
    id: 2,
    title: 'Asesorias',
    path: '#',
    perfil: [0],
    icon: getIcon('eva:people-fill'),
    component: Asesorias,
    params: {}
  },
  {
    id: 3,
    title: 'Empresas',
    path: '#',
    perfil: [0],
    icon: getIcon('eva:people-fill'),
    component: Empresas,
    params: {}
  },
  {
    id: 4,
    title: 'Trabajadores',
    path: '#',
    perfil: [0],
    icon: getIcon('eva:people-fill'),
    component: Trabajadores,
    params: {}
  },
  {
    id: 5,
    title: 'Contratos',
    path: '#',
    perfil: [0],
    icon: getIcon('eva:pie-chart-2-fill'),
    children: [{
        id: 51,
        title: 'Alta Contrato',
        path: '#',
        perfil: [0],
        icon: getIcon('eva:people-fill'),
        component: AltaContrato,
        params: {}
      },
      {
        id: 52,
        title: 'List. Contratos',
        path: '#',
        perfil: [0],
        icon: getIcon('eva:people-fill'),
        component: Contratos,
        params: {}
      }
    ]
  },
  {
    id: 6,
    title: 'Tutorización',
    path: '#',
    perfil: [0],
    icon: getIcon('eva:people-fill'),
    children: [{
        id: 61,
        title: 'Matriculaciones',
        path: '#',
        perfil: [0],
        icon: getIcon('eva:people-fill'),
        component: Matriculaciones,
        params: {}
      }
    ]
  },
  {
    id: 7,
    title: 'Liquidaciones',
    path: '#',
    perfil: [0],
    icon: getIcon('eva:pie-chart-2-fill'),
    children: [{
        id: 71,
        title: 'Liq. Redes Com.',
        path: '#',
        perfil: [0],
        icon: getIcon('eva:people-fill'),
        component: LiqRedes,
        params: {}
      },
      {
        id: 72,
        title: 'Liq. Asesorias',
        path: '#',
        perfil: [0],
        icon: getIcon('eva:people-fill'),
        component: LiqAsesorias,
        params: {}
      },
      {
        id: 73,
        title: 'Fact. Mensual',
        path: '#',
        perfil: [0],
        icon: getIcon('eva:people-fill'),
        component: Facturacion,
        params: {}
      },
      {
        id: 74,
        title: 'Marcar Pagados',
        path: '#',
        perfil: [0],
        icon: getIcon('eva:people-fill'),
        component: LiqPendientes,
        params: {}
      }
    ]
  },
  {
    id: 8,
    title: 'Maestros',
    path: '#',
    perfil: [0],
    icon: getIcon('eva:pie-chart-2-fill'),
    children: [{
        id: 81,
        title: 'Festivos',
        path: '#',
        perfil: [0],
        icon: getIcon('eva:people-fill'),
        component: Festivos,
        params: {}
      },
      {
        id: 82,
        title: 'Series Fact.',
        path: '#',
        perfil: [0],
        icon: getIcon('eva:people-fill'),
        component: SeriesFact,
        params: {}
      }
    ]
  },
];

const menuFilter = () => {
  if(user !== undefined){
    // eslint-disable-next-line no-plusplus
    for(let i=menuConfig.length-1; i>=0;i--){
      if(menuConfig[i].children!==undefined){
        // eslint-disable-next-line no-plusplus
        for(let f=menuConfig[i].children.length-1; f>=0;f--){
            if(!menuConfig[i].children[f].perfil.includes(user.idPerfil)){
              menuConfig[i].children.splice(f,1);
            }
        }
      }else if(!menuConfig[i].perfil.includes(user.idPerfil)){
          menuConfig.splice(i,1);
        }
    }
  }
  return menuConfig;
}

const menu  = menuFilter();

export default menu;
