import React from 'react';
// material
import { Card, CardContent, Divider, Button, CardHeader, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Swal from 'sweetalert2';
// components
import Page from '../../componentes/Page';
import Iconify from '../../componentes/Iconify';
// mock
import RTabla from '../../componentes/tabla';
import CONFIG from '../../CONFIG.json';
import { buildBody, isEmpty, getUniqueKey, workWithLocalStorage } from '../../auxiliares/funciones';
import RDateField from '../../componentes/forms/inputs/RDateField';
import { getToday } from '../../auxiliares/fechas';

import Editar from '../contratos/alta';

// ----------------------------------------------------------------------

export default function Index(props) {
  const [lista, setLista] = React.useState([]);
  const [filtro, setFiltro] = React.useState({desde:'',hasta:''});
  const [key, setKey] = React.useState(getUniqueKey());
  const usuario = workWithLocalStorage('get','usuario',null);

  props.showLoader(false);

    const campos = ['id','empresa','trabajador','fechaAlta','observaciones','estadocontrato','factura','fechaInicio','fechaFin','importe','estadoCobro'];    
    const cabecera = ['ID','Empresa','Trabajador','Alta Contrato','Notas','Estado','Factura','Inicio Fac.','Fin Fac.','Importe','Cobro'];
 
    const actualizaCampo = (input) =>{
        const nombre = input.target.name
        const valor = input.target.value
        const filtroA = filtro;
        filtroA[nombre] = valor
        setFiltro(filtroA);
    }

    const verContrato = (objeto) => props.setTabs({ nombre: `Editar Contrato #${objeto.id}`, componente: Editar, params: {id: objeto.id}, visible: true })

    const Buscar = () =>{
        if(filtro.desde !== '' && filtro.hasta !== ''){            
            props.showLoader(true); 
            const body = filtro;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
            fetch(`${CONFIG.api}liquidaciones/pendientes`,{
                    method: 'POST',
                    body: bodyJson,
                    headers: {'Content-Type': 'application/json'}
            })
            .then(res => res.json())
            .then((result) => {
                if (result.error === 0 && !isEmpty(result.data)) {
                    setLista(result.data);
                }else{
                    setLista([]);
                }
                setKey(getUniqueKey());
                // eslint-disable-next-line react/prop-types
                props.showLoader(false); 
            })
        }else{
            Swal.fire({
                title: 'Selecciona desde que fecha hasta que fecha de inicio de facturación',
                buttons: [{label: 'Ok'}]
            })
        }
    }

    const theme = useTheme();
    const acciones = [
      {
        color:undefined,
        nombre:'Ver',
        icono:<Iconify icon="eva:edit-fill" style={{ fontSize: '20px' }} />,
        funcion: verContrato,
        estilo: { backgroundColor: theme.palette.gradients.primary, color: theme.palette.common.white},
        condition: null
      }
    ]

    const Marcar = (accion) =>{
        const listaA = lista
        for(let i=0; i<lista.length; i += 1){
            listaA[i].marcado = accion ? 1 : 0;
        }
        setLista(listaA);
        setKey(getUniqueKey());
    }

    const MarcarCheck = (input) =>{
        const nombre = Number(input.target.name)
        const valor = input.target.value
        const index = lista.findIndex(x => x.idLiquidacion === nombre);
        const listaA = lista;
        listaA[index].marcado = valor;
        setLista(listaA)
    }

    const compruebaChecks = () =>{
        const ids = [];
        for(let i=0; i<lista.length; i += 1){
            if(lista[i].marcado === 1){
                ids.push(lista[i].idLiquidacion)
            }
        }
        return ids.join(',');
    }

  const MarcarPagados = () =>{
    const marcados = compruebaChecks();
    if(marcados !== ''){            
        props.showLoader(true); 
        const body = { lista: marcados };
        body.idUsuarioMod = usuario.id;
        body.estadoCobro = 'PAGADO';
        body.fechaCobro = getToday('datetime');
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
        fetch(`${CONFIG.api}liquidaciones/pendientes/marcar`,{
                method: 'PUT',
                body: bodyJson,
                headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then((result) => {
            if (result.error === 0) {
                Swal.fire({
                    title: 'Modificados!',
                    buttons: [{label: 'Ok'}]
                    })
                Buscar();
            }else{
                Swal.fire({
                    title: result.errorMessage,
                    buttons: [{label: 'Ok'}]
                    })
                props.showLoader(false); 
            }
        })
    }else {
        Swal.fire({
            title: 'Debes marcar alguno',
            buttons: [{label: 'Ok'}]
        })
    }
}

  return (    
    <Page key={lista.length} sx={{ width: '100% !important'}}>
        <Card  sx={{ maxWidth: '100% !important'}}>
            <CardHeader title="Marcar Pagados"/>
          <Divider style={{ margin: '15px'}} />
          <CardContent>
            <form style={{ width: '100%'}}>
                <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
                    <RDateField type="text" name="desde" label="Desde Inicio" actualizaCampo={actualizaCampo} value={filtro.desde} md={2} />
                    <RDateField type="text" name="hasta" label="Hasta Inicio" actualizaCampo={actualizaCampo} value={filtro.hasta} md={2} />
                    <Grid item md={2} sm={2}>
                        <Button variant="contained" color="success" sx={{ color: 'white'}} to="#" startIcon={<Iconify icon="eva:refresh-fill" />}
                            onClick={()=>Buscar()}>Buscar</Button>
                    </Grid>
                </Grid>
                {!isEmpty(lista) &&
                <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
                    <Grid item md={2} sm={2}>
                        <Button variant="contained" color="info" sx={{ color: 'white'}} to="#" startIcon={<Iconify icon="eva:checkmark-fill" />}
                            onClick={()=>Marcar(true)}>Marcar Todos</Button>
                    </Grid>
                    <Grid item md={2} sm={2}>
                        <Button variant="contained" color="warning" sx={{ color: 'white'}} to="#" startIcon={<Iconify icon="eva:close-fill" />}
                            onClick={()=>Marcar(false)}>Desmarcar Todos</Button>
                    </Grid>
                    <Grid item md={2} sm={2}>
                        <Button variant="contained" color="primary" sx={{ color: 'white'}} to="#" startIcon={<Iconify icon="eva:edit-outline" />}
                            onClick={()=>MarcarPagados(false)}>Modificar los marcados</Button>
                    </Grid>
                </Grid>
                }
            </form>
          </CardContent>
          <Divider style={{ margin: '15px'}} />
          <CardContent>
            <RTabla key={key} marcaCheck={MarcarCheck} check lista={lista} campos={campos} cabecera={cabecera} acciones={acciones}/>
          </CardContent>
        </Card>
    </Page>
  );
}
