import React from 'react';
// @mui
import { Grid, Button, Card, CardHeader, CardContent, Modal, Divider } from '@mui/material';
import Swal from 'sweetalert2';
import CloseIcon from '@mui/icons-material/Close';
// components
import Iconify from '../../../componentes/Iconify';

import { buildBody, isEmpty, workWithLocalStorage, compruebaForm, limpiaObjeto } from '../../../auxiliares/funciones';
import { getLiquidacion, getLiquidacionDetalle, getSeriesFact } from '../../../auxiliares/clases/contratos';
import { getToday } from '../../../auxiliares/fechas';
import CONFIG from '../../../CONFIG.json';
import RTabla from '../../../componentes/tabla';
import LiquisimpleForm from './liquisimpleform';
import RSelect from '../../../componentes/forms/inputs/RSelect';

// ----------------------------------------------------------------------
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    minWidth: '750px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    minWidth: '570px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

export default function BoniForm({ showLoader, contrato }) {   

  const [liqui, setLiqui] = React.useState([]);
  const [series, setSeries] = React.useState([]);
  const [key, setKey] = React.useState(Math.random());
  const [seriesMostrar, setSeriesMostrar] = React.useState([]);
  const [cargado, setCargado] = React.useState(false);
  const usuario = workWithLocalStorage('get','usuario',null);  

  React.useEffect(() => {
    async function cargaInicial(){
        if(!cargado){         
            setLiqui(await getLiquidacion(contrato.id));
            setSeries(await getSeriesFact())
            setCargado(true);
            showLoader(false); 
            setKey(Math.random());
        }
    }
    cargaInicial();
  // eslint-disable-next-line
  },[cargado]);

  const CalculaLiquidacion = () =>{
    // eslint-disable-next-line react/prop-types
    showLoader(true); 
    const body = { id : contrato.id, usuario: usuario.id};

    const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
    fetch(`${CONFIG.api}contrato/liquidacion/calcular`,{
            method: 'POST',
            body: bodyJson,
            headers: {'Content-Type': 'application/json'}
    })
    .then(res => res.json())
    .then((result) => {
        if (result.error === 0) {   
            Swal.fire({
              title: 'Liquidación calculada!',
              buttons: [{label: 'Ok'}]
            }) 
            showLoader(true);  
            setCargado(false);
        }else{
          // showLoader(false); 
          Swal.fire({
            title: result.errorMessage,
            buttons: [{label: 'Ok'}]
          })
        }
    })
}

const borrar = (obj) =>{
  showLoader(true); 
    const body = {
      tabla: "contratos_liquidaciones",
      activo: 0,
      usuario: usuario.id,
      id: obj.id
    }
    const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
    fetch(`${CONFIG.api}master/activo`,{
              method: 'PUT',
              body: bodyJson,
              headers: {'Content-Type': 'application/json'}
      })
      .then(res => res.json())
      .then((result) => {
        let msg ='Liquidación borrada!';
          if (result.error === 0) {      
            setCargado(false);
          }else{
            msg = result.errorMessage;
          }
          Swal.fire({
            title: msg,
            buttons: [{label: 'Ok'}]
          })
    })
}

const modificar = async (obj) =>{
    const li = await getLiquidacionDetalle(obj.id);
    nuevaLiq(li);
}

const facturar = (obj) =>{
  setLiqId(obj.id);
  setSeriesMostrar(series.filter(e => e.tipo === 0 && e.activo === 1));
  setNombreModalS('Elige serie de Débito para facturar');
  abreModalS(); 
}

const rectificar = (obj) =>{
  setLiqId(obj.id);
  setSeriesMostrar(series.filter(e => e.tipo === 1 && e.activo === 1));
  setNombreModalS('Elige serie de Crédito para facturar');
  abreModalS()
}

const descargar = (obj) =>{
    // showLoader(true);     
    fetch(`${CONFIG.api}docs/descarga?carpeta=contratos/${contrato.id}/facturas&id=${obj.docid}&idReferencia=${obj.id}&API_TOKEN=${CONFIG.TOKEN}`,{
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
        responseType: 'blob'
    })
    .then(response => response.blob())
    .then(blob => {
    const url = window.URL.createObjectURL(blob);
    window.open(url,'_blank')
    // showLoader(false); 
    })
}

const muestraEstadoCobro = (obj) =>{
  setEstadoCobro(obj.estadoCobro);
  setLiqId(obj.id);
  setNombreModalC('Elige el estado actual de la factura');
  abreModalC(); 
}

const compruebaDoc = (obj) => obj.docid !== 0;
const compruebaFact = (obj) => obj.factura === '' && obj.rectificativa === '';
const compruebaRect = (obj) => obj.factura !== '' && obj.rectificativa === '';
const compruebaBorrar = (obj) => obj.factura === '' && obj.rectificativa === '';
const compruebaMod = () => {return true;} // (obj) => obj.factura === '' && obj.rectificativa === '';

const acciones = [{
    color:'secondary',
    nombre:'',
    icono:<Iconify icon="eva:edit-fill" style={{ fontSize: '20px' }} />,
    funcion: modificar,
    estilo: {},
    condition: compruebaMod
  },{
    color:'secondary',
    nombre:'Estado Cobro',
    icono:<Iconify icon="eva:credit-card-outline" style={{ fontSize: '20px' }} />,
    funcion: muestraEstadoCobro,
    estilo: {},
    condition: compruebaDoc
  },{
    color:'info',
    nombre:'Doc.',
    icono:<Iconify icon="eva:download-fill" style={{ fontSize: '20px' }} />,
    funcion: descargar,
    estilo: {},
    condition: compruebaDoc
  },{
    color:'success',
    nombre:'Facturar',
    icono:<i />,
    funcion: facturar,
    estilo: {},
    condition: compruebaFact
  },{
    color:'warning',
    nombre:'Rectificar',
    icono:<i />,
    funcion: rectificar,
    estilo: {},
    condition: compruebaRect
  },{
    color:'error',
    nombre:'',
    icono:<Iconify icon="eva:trash-2-fill" style={{ fontSize: '20px' }} />,
    funcion: borrar,
    estilo: {},
    condition: compruebaBorrar
  }]

  /* ***************** TABLA ************************** */
  const campos = ['fechaInicio','fechaFin','fechaVencimiento','dias','horas','importe','factura','rectificativa','notas','estadoCobro','fechaAlta','usuarioAlta','estadoContrato'];    
  const cabecera = ['Inicio','Fin','Venc.','Días','Horas','Importe','Nº Factura','Fact. Rect','Notas','Est. Cobro','Alta','Usuario','Est. Contrato'];

  /* ****************** FORM ******************************** */
  const FormLiq = {
    fechaInicio: '',
    fechaFin: '',
    fechaVencimiento: '',
    dias : 0,
    horas: 0,
    importe: 0,
    factura: '',
    rectificativa: '',
    notas: ''
  };
const obligatorios = ['fechaInicio','fechaFin','fechaVencimiento','dias','horas','importe'];

const [modalOpen, setModalOpen] = React.useState(false);
const [liquidacion, setLiquidacion] = React.useState(FormLiq);
const [nombreModal, setNombreModal] = React.useState('');
const [modalOpenS, setModalOpenS] = React.useState(false);
const [modalOpenC, setModalOpenC] = React.useState(false);
const [nombreModalS, setNombreModalS] = React.useState('');
const [nombreModalC, setNombreModalC] = React.useState('');
const [serieId, setSerieId] = React.useState(0);
const [estadoCobro, setEstadoCobro] = React.useState(0);
const [liqId, setLiqId] = React.useState(0);
const cierraModal = () => setModalOpen(false);
const abreModal = () => setModalOpen(true);
const cierraModalS = () => setModalOpenS(false);
const abreModalS = () => setModalOpenS(true);
const cierraModalC = () => setModalOpenC(false);
const abreModalC = () => setModalOpenC(true);

const nuevaLiq = (obj) =>{
    setLiquidacion(obj === null ? FormLiq : obj);
    setNombreModal(obj === null ? 'Alta Liquidación' : `Editar Liquidación #${obj.id}`);
    abreModal();
}

const actualizaCampo = (input) =>{
    const nombre = input.target.name
    const valor = input.target.value
    const comA = liquidacion;
    comA[nombre] = valor
    setLiquidacion(comA);
}

const grabarLiq = () =>{
    const liquid = limpiaObjeto(liquidacion,FormLiq,true)
    const valido = compruebaForm(FormLiq,liquid,obligatorios);
    if(valido){
        // eslint-disable-next-line react/prop-types
        showLoader(true); 
        const body = liquid;
        body.idUsuarioAlta = usuario.id;
        body.idUsuarioMod = usuario.id;
        if(liquid.idContrato === undefined){
            body.idContrato = contrato.id;
            body.idEstado = contrato.idEstado;
        }
        const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
        fetch(`${CONFIG.api}contrato/liquidacion/detalle`,{
                method: 'PUT',
                body: bodyJson,
                headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then((result) => {
            let msg ='Liquidación grabada!';
            if (result.error === 0) { 
                cierraModal();  
                setCargado(false);
            }else{
                showLoader(false); 
                msg = result.errorMessage;
            }
            Swal.fire({
                title: msg,
                buttons: [{label: 'Ok'}]
            })
        })
    }else{
        Swal.fire({
            title: 'Revisa los campos con *',
            buttons: [{label: 'Ok'}]
        })
    }
}

const facturarLiq = () =>{
        // eslint-disable-next-line react/prop-types
        showLoader(true); 
        const body = {};
        body.idUsuarioAlta = usuario.id;
        body.idSerie = serieId;
        body.idLiquidacion = liqId;
        body.idContrato = contrato.id;
        const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
        fetch(`${CONFIG.api}contrato/liquidacion/factura`,{
                method: 'PUT',
                body: bodyJson,
                headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then((result) => {
            let msg ='Documento generado!';
            if (result.error === 0) { 
                cierraModalS();  
                setCargado(false);
            }else{
                showLoader(false); 
                msg = result.errorMessage;
            }
            Swal.fire({
                title: msg,
                buttons: [{label: 'Ok'}]
            })
        })
}

const modificarEstadoCobro = () =>{
      // eslint-disable-next-line react/prop-types
      showLoader(true); 
      const body = {};
      body.idUsuarioMod = usuario.id;
      body.estadoCobro = estadoCobro;
      if(estadoCobro === 'PAGADO'){        
        body.fechaCobro = getToday('datetime');
      }
      body.id = liqId;
      const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
      fetch(`${CONFIG.api}contrato/liquidacion/detalle`,{
              method: 'PUT',
              body: bodyJson,
              headers: {'Content-Type': 'application/json'}
      })
      .then(res => res.json())
      .then((result) => {
          let msg ='Cobro modificado!';
          if (result.error === 0) { 
              cierraModalC();  
              setCargado(false);
          }else{
              showLoader(false); 
              msg = result.errorMessage;
          }
          Swal.fire({
              title: msg,
              buttons: [{label: 'Ok'}]
          })
      })
}

  return (
    <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <Grid item md={12}>
          <Button variant="contained" color="warning" sx={{ color: 'white', marginRight: '15px'}} to="#" startIcon={<Iconify icon="eva:file-text-outline" />}
          onClick={()=>CalculaLiquidacion()}> Calcular Liquidación </Button>
          <Button variant="contained" color="info" sx={{ color: 'white', marginRight: '15px'}} to="#" onClick={()=>nuevaLiq(null)}> Alta Liquidación </Button>
        </Grid>        
        {!isEmpty(liqui) &&
        <Grid item md={12}>
            <RTabla key={key} lista={liqui} campos={campos} cabecera={cabecera} acciones={acciones}/>
        </Grid>
        }
        <Modal open={modalOpen} onClose={cierraModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Card sx={style}>
                <CardHeader title={nombreModal}
                action={<>
                    <Button variant="contained" color="primary" sx={{ color: 'white', marginRight: '15px'}} to="#" startIcon={<Iconify icon="eva:edit-outline" />}
                    onClick={()=>grabarLiq()}>
                        Grabar
                    </Button>
                    <CloseIcon fontSize="inherit" onClick={() => cierraModal() }/>
                </>}/>
                <Divider style={{ margin: '15px'}} />
                <CardContent>
                    <LiquisimpleForm showLoader={showLoader} contratoid={contrato.id} liquidacion={liquidacion} actualizaCampo={actualizaCampo}/>
                </CardContent>
            </Card>
        </Modal>
        <Modal open={modalOpenS} onClose={cierraModalS} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Card sx={style2}>
                <CardHeader title={nombreModalS}
                action={<>
                    <Button variant="contained" color="primary" sx={{ color: 'white', marginRight: '15px'}} to="#" startIcon={<Iconify icon="eva:file-text-outline" />}
                    onClick={()=>facturarLiq()}>
                        Generar
                    </Button>
                    <CloseIcon fontSize="inherit" onClick={() => cierraModalS() }/>
                </>}/>
                <Divider style={{ margin: '15px'}} />
                <CardContent>
                  <RSelect name="serie" id="serie" label="Serie*" md={8} values={seriesMostrar} value={0} actualizaCampo={(input)=>{setSerieId(input.target.value)}}/>
                </CardContent>
            </Card>
        </Modal>
        <Modal open={modalOpenC} onClose={cierraModalC} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Card sx={style2}>
                <CardHeader title={nombreModalC}
                action={<>
                    <Button variant="contained" color="primary" sx={{ color: 'white', marginRight: '15px'}} to="#" startIcon={<Iconify icon="eva:file-text-outline" />}
                    onClick={()=>modificarEstadoCobro()}>
                        Modificar
                    </Button>
                    <CloseIcon fontSize="inherit" onClick={() => cierraModalC() }/>
                </>}/>
                <Divider style={{ margin: '15px'}} />
                <CardContent>
                  <RSelect name="estadoCobro" id="estadoCobro" label="Estado cobro*" md={8} values={[{id:'PENDIENTE',nombre:'PENDIENTE'},{id:'PAGADO',nombre:'PAGADO'},{id:'IMPAGADO',nombre:'IMPAGADO'}]} value={estadoCobro} actualizaCampo={(input)=>{setEstadoCobro(input.target.value)}}/>
                </CardContent>
            </Card>
        </Modal>
    </Grid>
  );
}
