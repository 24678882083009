import React from 'react';
// @mui
import { Grid, Button } from '@mui/material';
import Swal from 'sweetalert2';
// components
import RSelect from '../../../componentes/forms/inputs/RSelect';
import Iconify from '../../../componentes/Iconify';

import { buildBody, isEmpty, getUniqueKey, workWithLocalStorage } from '../../../auxiliares/funciones';
import { getEspecialidades } from '../../../auxiliares/clases/contratos';
import CONFIG from '../../../CONFIG.json';
import RTabla from '../../../componentes/tabla';

// ----------------------------------------------------------------------

export default function EspForm({ showLoader, contrato, catalogo }) {        
  const [key, setKey] = React.useState(Math.random());
  const [especialidad, setEspecialidad] = React.useState(0);
  const [cargado, setCargado] = React.useState(false);
  const [list, setList] = React.useState([]);
  const [especialidades] = React.useState(catalogo); // React.useState(catalogo.filter(a => a.idFamilia === contrato.idFamiliaProfesional));
  const usuario = workWithLocalStorage('get','usuario',null); 

  React.useEffect(() => {
    async function cargaInicial(){
        if(!cargado){
            setList(await getEspecialidades(contrato.id));            
            setKey(getUniqueKey());  
            setCargado(true);            
            showLoader(false); 
        }
    }
    cargaInicial();
  // eslint-disable-next-line
  },[cargado]);

  const compruebaExisteEsp = (id) => list.filter(l => l.idEspecialidad === id)

  const addEspecialidad = () =>{
    if(especialidad === 0){
        Swal.fire({
            title: 'Debes seleccionar una especialidad',
            buttons: [{label: 'Ok'}]
        })
    }else if(!isEmpty(compruebaExisteEsp(especialidad))){
        Swal.fire({
            title: 'Esta especialidad ya está añadida',
            buttons: [{label: 'Ok'}]
        })
    }else{
        // eslint-disable-next-line react/prop-types
        showLoader(true); 
        const body = {
            idContrato: contrato.id,
            idUsuarioAlta: usuario.id,
            idEspecialidad: especialidad,
            idEstado: contrato.idEstado,
            orden: list.length+1
        }
        const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
        fetch(`${CONFIG.api}contrato/especialidad`,{
                method: 'PUT',
                body: bodyJson,
                headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then((result) => {
            let msg ='Especialidad añadida!';
            if (result.error === 0) {   
                setCargado(false);          
            }else{
                msg = result.errorMessage;
                setCargado(false); 
            }
            showLoader(false); 
            Swal.fire({
                title: msg,
                buttons: [{label: 'Ok'}]
            })
        })          
    }
  }

    /* ***************** TABLA ************************** */
    const campos = ['orden','nombre','codigo','horas','estado','fechaAlta','usuario'];
    const cabecera = ['Orden','Nombre','Código','Horas','Estado','Fecha Alta','Usuario'];


    const BorrarEsp = (obj) =>{
        // eslint-disable-next-line react/prop-types
        showLoader(true); 
        const body = {
            tabla: "contratos_especialidades",
            activo: 0,
            usuario: usuario.id,
            id: obj.id
          }
          const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
          fetch(`${CONFIG.api}master/activo`,{
                    method: 'PUT',
                    body: bodyJson,
                    headers: {'Content-Type': 'application/json'}
            })
          .then(res => res.json())
          .then((result) => {
              let msg ='Especialidad borrada!';
              if (result.error === 0) {   
                  setCargado(false);          
              }else{
                  msg = result.errorMessage;
                  setCargado(false); 
              }
              showLoader(false); 
              Swal.fire({
                title: msg,
                buttons: [{label: 'Ok'}]
              })
        })
    }

    const validaSubir = (obj,index) => index !== 0;
    const validaBajar = (obj,index) => index < (list.length-1);

    const SubirEsp = (obj) => cambiarOrden(obj,-1)
    const BajarEsp = (obj) => cambiarOrden(obj,1)

    const cambiarOrden = (obj,accion) =>{
        // eslint-disable-next-line react/prop-types
        showLoader(true); 
        const body = {
            contrato: contrato.id,
            usuario: usuario.id,
            id: obj.id,
            accion
          }
          const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
          fetch(`${CONFIG.api}contrato/especialidades/orden`,{
                    method: 'PUT',
                    body: bodyJson,
                    headers: {'Content-Type': 'application/json'}
            })
          .then(res => res.json())
          .then((result) => {
              if (result.error === 0) {   
                  setCargado(false);      
              }else{
                  Swal.fire({
                    title: result.errorMessage,
                    buttons: [{label: 'Ok'}]
                  })
                  showLoader(false); 
              }
        })
    }
    
    const acciones = [{
        color:'primary',
        nombre:'',
        icono:<Iconify icon="eva:arrow-upward-fill" style={{ fontSize: '20px' }} />,
        funcion: SubirEsp,
        estilo: {},
        condition: validaSubir
      },{
        color:'primary',
        nombre:'',
        icono:<Iconify icon="eva:arrow-downward-fill" style={{ fontSize: '20px' }} />,
        funcion: BajarEsp,
        estilo: {},
        condition: validaBajar
      },{
        color:'error',
        nombre:'Borrar',
        icono:<i />,
        funcion: BorrarEsp,
        estilo: {},
        condition: null
      }]

  return (
    <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <RSelect name="idEspecialidad" label="Especialidad*" actualizaCampo={e=> setEspecialidad(e.target.value)} value={especialidad} md={6}
        values={especialidades} searchable={1} grouping={1} position={"left"}/>            
        <Grid item md={1}>    
            <Button onClick={addEspecialidad} variant="contained">Añadir</Button>
        </Grid> 
        {!isEmpty(list) &&
        <Grid item md={12}>
            <RTabla key={key} lista={list} campos={campos} cabecera={cabecera} acciones={acciones}/>
        </Grid>
        }
    </Grid>
  );
}
