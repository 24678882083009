import React from 'react';
// material
import { Card, CardContent, Divider, Button, CardHeader, Grid } from '@mui/material';
import Swal from 'sweetalert2';
// components
import Page from '../../componentes/Page';
import Iconify from '../../componentes/Iconify';
// mock
import RTabla from '../../componentes/tabla';
import CONFIG from '../../CONFIG.json';
import { buildBody, isEmpty, getUniqueKey, workWithLocalStorage } from '../../auxiliares/funciones';
import RTextField from '../../componentes/forms/inputs/RTextField';
import RSelect from '../../componentes/forms/inputs/RSelect';

// ----------------------------------------------------------------------

export default function Index(props) {
    const [lista, setLista] = React.useState([]);
    const [filtro, setFiltro] = React.useState({mes:0,anyo:2024});
    const [key, setKey] = React.useState(getUniqueKey());
    const [keyF, setKeyF] = React.useState(getUniqueKey());
    const [cargado, setCargado] = React.useState(false);
    const usuario = workWithLocalStorage('get','usuario',null);

    React.useEffect(() => {
        async function cargaInicial(){
            props.showLoader(false);
            if(!cargado){
                props.showLoader(true);
                const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,{}))
                fetch(`${CONFIG.api}liquidaciones/facturas`,{
                    method: 'POST',
                    body: bodyJson,
                    headers: {'Content-Type': 'application/json'}
                })
                    .then(res => res.json())
                    .then((result) => {
                        if (result.error === 0 && !isEmpty(result.data)) {
                            setLista(result.data);
                        }else{
                            setLista([])
                        }
                        setKey(getUniqueKey());
                        // eslint-disable-next-line react/prop-types
                        props.showLoader(false);
                        setCargado(true);
                })
            }
        }
        cargaInicial();
        // eslint-disable-next-line
    },[cargado]);

    const actualizaCampo = (input) =>{
        const nombre = input.target.name
        const valor = input.target.value
        const filtroA = filtro;
        filtroA[nombre] = valor
        setFiltro(filtroA);
        setKeyF(getUniqueKey());
    }

    const campos = ['notas','fechaAlta','usuario'];
    const cabecera = ['Descripción','Fecha Alta','Usuario'];

    const descargar = (obj) =>{
        // eslint-disable-next-line react/prop-types
        props.showLoader(true);
        console.log(obj)
        fetch(`${CONFIG.api}docs/descarga?carpeta=facturacion&id=${obj.id}&idReferencia=${obj.idReferencia}&API_TOKEN=${CONFIG.TOKEN}`,{
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
            responseType: 'blob'
        })
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                window.open(url,'_blank')
                props.showLoader(false);
            })
    }

    const Facturar = () =>{
        if(filtro.mes !== 0 && filtro.anyo !== '' ){
            props.showLoader(true);
            const body = filtro;
            body.idUsuarioAlta = usuario.id
            // eslint-disable-next-line react-hooks/exhaustive-deps
            const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
            fetch(`${CONFIG.api}liquidaciones/facturas`,{
                method: 'PUT',
                body: bodyJson,
                headers: {'Content-Type': 'application/json'}
            })
                .then(res => res.json())
                .then((result) => {
                    let msg = '';
                    if (result.error === 0) {
                        setCargado(false);
                        msg = `Liquidadas ${result.data.data.doit} facturas de ${result.data.data.num} total.`
                    }else{
                        msg = result.errorMessage;
                    }
                    Swal.fire({
                        title: msg,
                        buttons: [{label: 'Ok'}]
                    })
                    // eslint-disable-next-line react/prop-types
                    props.showLoader(false);
                })
        }else{
            Swal.fire({
                title: 'Selecciona el mes y el año de facturación',
                buttons: [{label: 'Ok'}]
            })
        }
    }

    const Comprobar = () =>{
        props.showLoader(true);
        const body = filtro;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
        fetch(`${CONFIG.api}liquidaciones/facturas/check`,{
            method: 'PUT',
            body: bodyJson,
            headers: {'Content-Type': "application/json"},
            responseType: "arraybuffer"
        })
            // .then((response) => {
            .then(res => res.blob())
            .then((blob) =>{
                if(blob.type !== 'application/json'){
                    const file = window.URL.createObjectURL(new Blob([blob]));
                    const fileLink = document.createElement('a');
                    fileLink.href = file;
                    fileLink.setAttribute('download', `${filtro.mes}_${filtro.anyo}.xlsx`);
                    fileLink.setAttribute('target', '_blank');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    fileLink.remove();
                }else{
                    Swal.fire({
                        title: 'No hay registros con ese filtro',
                        buttons: [{label: 'Ok'}]
                    })
                }
                // eslint-disable-next-line react/prop-types
                props.showLoader(false);
            })
    }

    const acciones = [
        {
            color:'success',
            nombre:'Descargar',
            icono:<i />,
            funcion: descargar,
            estilo: {},
            condition: null
        }
    ]

    const meses = [{ id: 1, nombre: 'Enero'},
        { id: 2, nombre: 'Febrero'},
        { id: 3, nombre: 'Marzo'},
        { id: 4, nombre: 'Abril'},
        { id: 5, nombre: 'Mayo'},
        { id: 6, nombre: 'Junio'},
        { id: 7, nombre: 'Julio'},
        { id: 8, nombre: 'Agosto'},
        { id: 9, nombre: 'Septiembre'},
        { id: 10, nombre: 'Octubre'},
        { id: 11, nombre: 'Noviembre'},
        { id: 12, nombre: 'Diciembre'}]

    return (
        <Page key={keyF} sx={{ width: '100% !important'}}>
            <Card  sx={{ maxWidth: '100% !important'}}>
                <CardHeader title="Facturación Mensual"/>
                <Divider style={{ margin: '15px'}} />
                <CardContent>
                    <form style={{ width: '100%'}}>
                        <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
                            <RSelect name="mes" label="Mes" actualizaCampo={actualizaCampo} value={filtro.mes} md={2} values={meses} />
                            <RTextField type="text" name="anyo" label="Año" actualizaCampo={actualizaCampo} value={filtro.anyo} md={2} />
                                <Grid item md={2} sm={2}>
                                    <Button variant="contained" color="primary" sx={{ color: 'white'}} to="#" onClick={()=>Comprobar()}>Comprobar</Button>
                                </Grid>
                                {filtro.mes !== 0 &&
                                <Grid item md={2} sm={2}>
                                    <Button variant="contained" color="success" sx={{ color: 'white'}} to="#" startIcon={<Iconify icon="eva:refresh-fill" />}
                                            onClick={()=>Facturar()}>Facturar</Button>
                                </Grid>
                                }
                        </Grid>
                    </form>
                </CardContent>
                <Divider style={{ margin: '15px'}} />
                <CardContent>
                    <RTabla key={key} lista={lista} campos={campos} cabecera={cabecera} acciones={acciones}/>
                </CardContent>
            </Card>
        </Page>
    );
}
