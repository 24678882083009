import React from 'react';
// components
import Divider from "@mui/material/Divider";
import { Button, Card, CardHeader, CardContent } from '@mui/material';
import Swal from 'sweetalert2';

import Page from '../../componentes/Page';
import Iconify from '../../componentes/Iconify';
import CONFIG from '../../CONFIG.json';
import { buildBody, isEmpty, getUniqueKey, workWithLocalStorage, compruebaForm } from '../../auxiliares/funciones';
import { getProvincias, getComerciales } from '../../auxiliares/maestros';
import AseForm from './form/asesoriaform';
import Empresas from '../empresas';

// ----------------------------------------------------------------------
export default function AltaForm(props) {
    const usuario = workWithLocalStorage('get','usuario',null);
    const Form = {
        idComercial:0,
        nombre: '',
        telefono: '',
        email: '',
        direccion: '',
        cp: '',
        localidad: '',
        idProvincia: 0,
        cif: '',
        comision: null,
        tipoComision:null,
        activo: 1,
      };
    const obligatorios = ['idComercial','nombre','telefono','email','cif','comision','tipoComision'];

    const [asesoria, setAsesoria] = React.useState(Form);
    const [cargado, setCargado] = React.useState(false);
    const [provincias, setProvincias] = React.useState([]);
    const [comerciales, setComerciales] = React.useState([]);
    const [id,setId] = React.useState(props.params.id ? props.params.id : '0');

    React.useEffect(() => {
        async function cargaInicial(){
            if(!cargado){         
                setProvincias(await getProvincias());
                setComerciales(await getComerciales());
                
                if(id !== '0'){                
                    // eslint-disable-next-line
                    const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,{ id:id }))
                    fetch(`${CONFIG.api}asesoria/detalle`,{
                                method: 'POST',
                                body: bodyJson,
                                headers: {'Content-Type': 'application/json'}
                        })
                        .then(res => res.json())
                        .then((result) => {
                        if (result.error === 0 && !isEmpty(result.data)) {
                            setAsesoria(result.data);
                        }
                        props.showLoader(false);            
                        setCargado(true);
                    })
                }else{
                    setCargado(true);
                }
            }
        }
        cargaInicial();
      // eslint-disable-next-line
      },[cargado]);

    const Grabar = () =>{
        const valido = compruebaForm(Form,asesoria,obligatorios);
        if(valido){
            // eslint-disable-next-line react/prop-types
            props.showLoader(true); 
            const body = asesoria;
            body.idUsuarioAlta = usuario.id;
            body.idUsuarioMod = usuario.id;
            const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
            fetch(`${CONFIG.api}asesoria/detalle`,{
                    method: 'PUT',
                    body: bodyJson,
                    headers: {'Content-Type': 'application/json'}
            })
            .then(res => res.json())
            .then((result) => {
                let msg ='Asesoría grabada!';
                if (result.error === 0) {   
                    setId(result.data.id);
                    document.getElementById(`simple-tab-${props.index}`).getElementsByTagName( 'span' )[0].textContent = `Editar Asesoría #${result.data.id} `;
                    setCargado(false);
                }else{
                    props.showLoader(false); 
                    msg = result.errorMessage;
                }
                Swal.fire({
                title: msg,
                buttons: [{label: 'Ok'}]
                })
            })
        }else{
            Swal.fire({
                title: 'Revisa los campos con *',
                buttons: [{label: 'Ok'}]
            })
        }
    }

    const Nuevo = () =>{
        setAsesoria(Form);
        setId('0')
        document.getElementById(`simple-tab-${props.index}`).getElementsByTagName( 'span' )[0].textContent = 'Alta Asesoría ';
    }
    
    const actualizaCampo = (input) =>{
        const nombre = input.target.name
        const valor = input.target.value
        const asesoriaA = asesoria;
        asesoriaA[nombre] = valor
        setAsesoria(asesoriaA);
    }

    const Activar = () =>{
        // eslint-disable-next-line react/prop-types
        props.showLoader(true); 
        const body = {
          tabla: "asesorias",
          activo: 1,
          usuario: usuario.id,
          id: asesoria.id
        }
        const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
        fetch(`${CONFIG.api}master/activo`,{
                  method: 'PUT',
                  body: bodyJson,
                  headers: {'Content-Type': 'application/json'}
          })
          .then(res => res.json())
          .then((result) => {
            let msg ='Asesoría activada!';
            if (result.error === 0) {   
                setCargado(false);
            }else{
              props.showLoader(false); 
              msg = result.errorMessage;
            }
            Swal.fire({
              title: msg,
              buttons: [{label: 'Ok'}]
            })
        })
    }

    const Borrar = () =>{
        // eslint-disable-next-line react/prop-types
        props.showLoader(true); 
        const body = {
            tabla: "asesorias",
            activo: 0,
            usuario: usuario.id,
            id: asesoria.id
        }
        const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
        fetch(`${CONFIG.api}master/activo`,{
                    method: 'PUT',
                    body: bodyJson,
                    headers: {'Content-Type': 'application/json'}
            })
            .then(res => res.json())
            .then((result) => {
                let msg ='Asesoría desactivada!';
                if (result.error === 0) {   
                    setCargado(false);
                }else{
                    props.showLoader(false); 
                    msg = result.errorMessage;
                }
                props.showLoader(false); 
                Swal.fire({
                title: msg,
                buttons: [{label: 'Ok'}]
                })
        })
    }
    
    return (
        <Page title="Alta Asesoría" key={getUniqueKey()}>
            <Card  sx={{ maxWidth: '100% !important'}}>
                <CardHeader action={<>
                                    <Button variant="contained" color="success" sx={{ color: 'white', marginRight: '15px'}} to="#" startIcon={<Iconify icon="eva:plus-fill" />}
                                    onClick={()=>Grabar(null)}>
                                        Grabar
                                    </Button>
                                    <Button variant="contained" color="primary" sx={{ color: 'white', marginRight: '15px'}} to="#" startIcon={<Iconify icon="eva:edit-outline" />}
                                    onClick={()=>Nuevo()}>
                                        Nuevo
                                    </Button>
                                    {asesoria.id &&
                                    (asesoria.activo === 1 ?
                                    <Button variant="contained" color="error" sx={{ color: 'white'}} to="#" startIcon={<Iconify icon="eva:trash-2-outline" />}
                                    onClick={()=>Borrar()}>
                                        Borrar
                                    </Button>
                                    : <Button variant="contained" color="warning" sx={{ color: 'white'}} to="#" startIcon={<Iconify icon="eva:refresh-fill" />}
                                    onClick={()=>Activar()}>
                                        Activar
                                    </Button>)
                                    }
                                    </>}
                        title={id === '0' ? "Alta Asesoría" : `Editar Asesoría #${id}`}
                />
                <Divider style={{ margin: '15px'}} />
                <CardContent>
                    {cargado &&
                    <AseForm showLoader={props.showLoader} provincias={provincias} comerciales={comerciales} asesoria={asesoria} actualizaCampo={actualizaCampo}/>
                    }
                </CardContent>
                {asesoria.id && <Divider style={{ margin: '15px'}} />}
                {asesoria.id && 
                <CardContent>
                    <Empresas idAsesoria={id} showLoader={props.showLoader} setTabs={props.setTabs}/>
                </CardContent>
                }
            </Card>
        </Page>
    );
}
