import React from 'react';
// @mui
import 'dayjs/locale/es';
import { TextField, Grid } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { dateToStringDate, stringDateToDate } from '../../../auxiliares/fechas';

// ----------------------------------------------------------------------


export default function RDateField({ name, value, label, actualizaCampo, md, ...other }) {
  const [val,setVal] = React.useState(value === '' ? '' : stringDateToDate(value));
  const sm = md * 2;


  const refreshValue = (e) =>{
      if(e === null){
        actualizaCampo({target:{name,value:''}});
        setVal('');
      }else{
        setVal(e);
        actualizaCampo({target:{name,value:dateToStringDate(e.$d)}});
      }
  }

  return (
    <Grid item md={md} sm={sm}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
        <DesktopDatePicker label={label} inputFormat="DD-MM-YYYY" 
           onChange={(e)=>{refreshValue(e);}} value={val} name={name}
          renderInput={(params) => <TextField type="number" fullWidth {...params} error={false} autoComplete="new-password" InputLabelProps={{ shrink: true }} {...other} />} 
        />
        </LocalizationProvider>
    </Grid>
  );
}
