import React from 'react';
// @mui
import { Grid, FormLabel } from '@mui/material';

// ----------------------------------------------------------------------


export default function RTextField({ label, md }) {
  const sm = md * 2;

  return (
    <Grid item md={md} sm={sm}>
        <FormLabel>{label}</FormLabel>
    </Grid>
  );
}
