import React from 'react';
// @mui
import { Grid, Button } from '@mui/material';
import FileUpload from "react-mui-fileuploader";
import Swal from 'sweetalert2';
// components
import RTextField from '../../../componentes/forms/inputs/RTextField';
import RDateField from '../../../componentes/forms/inputs/RDateField';
import CONFIG from '../../../CONFIG.json';
import { buildBody, isEmpty, getUniqueKey, workWithLocalStorage } from '../../../auxiliares/funciones';

// ----------------------------------------------------------------------

export default function LiqSimpleForm({ showLoader, actualizaCampo, liquidacion, contratoid }) {   
    const [fileKey, setFileKey] = React.useState(getUniqueKey());
    const [filesForm, setFiles] = React.useState([]);    
    const [doc, setDoc] = React.useState(0);
    const usuario = workWithLocalStorage('get','usuario',null);  

    React.useEffect(() => {
        showLoader(false); 
        if(liquidacion.docid !== ''){
            setDoc(liquidacion.docid)
        }
        // eslint-disable-next-line
    },[]);

    const descargarDoc = () =>{
        // showLoader(true);     
        fetch(`${CONFIG.api}docs/descarga?carpeta=contratos/${contratoid}/facturas&id=${doc}&idReferencia=${liquidacion.id}&API_TOKEN=${CONFIG.TOKEN}`,{
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
                responseType: 'blob'
        })
        .then(response => response.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          window.open(url,'_blank')
          // showLoader(false); 
        })
    }

    const borrarDoc = () =>{
        showLoader(true); 
        const body = {
          carpeta: `contratos/${contratoid}/facturas`,
          id: doc,
          idReferencia: liquidacion.id,
        }
        const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
        fetch(`${CONFIG.api}docs/borrar`,{
                  method: 'PUT',
                  body: bodyJson,
                  headers: {'Content-Type': 'application/json'}
          })
          .then(res => res.json())
          .then((result) => {
              let msg ='Documento borrado!';
              if (result.error === 0) {   
                  setDoc(0);          
              }else{
                  msg = result.errorMessage;
              }
              showLoader(false); 
              Swal.fire({
                title: msg,
                buttons: [{label: 'Ok'}]
              })
        })
    }

    const handleFilesChange = (files) => {
        // Update chosen files
        setFiles([...files]);
    };

    const uploadFiles = () =>{    
        // Create a form and post it to server
        const formData = new FormData();
        filesForm.forEach((file) => formData.append("files", file,file.name));

        /* fetch("/file/upload", {
            method: "POST",
            body: formData
        }); */
        if(!isEmpty(filesForm)){ 
            // eslint-disable-next-line react/prop-types
            showLoader(true); 
            const body = {};
            body.id = liquidacion.id;
            body.idUsuarioAlta = usuario.id;
            body.carpeta = `contratos/${contratoid}/facturas`;
            const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
            formData.append("str", bodyJson);
            fetch(`${CONFIG.api}docs/subir`,{
                    method: 'POST',
                    body: formData
            })
            .then(res => res.json())
            .then((result) => {
                let msg ='Documento grabado!';
                if (result.error === 0) {
                    
                    const body2 = {
                        carpeta: `contratos/${contratoid}/facturas`,
                        id: doc,
                        idReferencia: liquidacion.id,
                      }
                    const bodyJson2 = JSON.stringify(buildBody(CONFIG.TOKEN,body2))
                    fetch(`${CONFIG.api}docs/borrar`,{
                            method: 'PUT',
                            body: bodyJson2,
                            headers: {'Content-Type': 'application/json'}
                    })

                    setDoc(result.data.id);   
                    setFiles([]);
                    setFileKey(getUniqueKey());
                }else{
                    msg = result.errorMessage;
                }
                showLoader(false); 
                Swal.fire({
                title: msg,
                buttons: [{label: 'Ok'}]
                })
            }) 
        }else{
            Swal.fire({
                title: 'Debes seleccionar un documento',
                buttons: [{label: 'Ok'}]
            })
        }
    }

    return (
        <form style={{ width: '100%'}}>
            <Grid container spacing={1} sx={{ marginBottom: '30px' }}>        
                <RDateField name="fechaInicio" label="Fecha Inicio*" actualizaCampo={actualizaCampo} value={liquidacion.fechaInicio} md={3} />
                <RDateField name="fechaFin" label="Fecha Fin*" actualizaCampo={actualizaCampo} value={liquidacion.fechaFin} md={3} />
                <RDateField name="fechaVencimiento" label="Fecha Venc.*" actualizaCampo={actualizaCampo} value={liquidacion.fechaVencimiento} md={3} />
            </Grid>
            <Grid container spacing={1} sx={{ marginBottom: '30px' }}>     
                <RTextField type="number" name="dias" label="Días*" actualizaCampo={actualizaCampo} value={liquidacion.dias} md={2} />
                <RTextField type="number" name="horas" label="Horas*" actualizaCampo={actualizaCampo} value={liquidacion.horas} md={2} />
                <RTextField type="number" name="importe" label="Importe*" actualizaCampo={actualizaCampo} value={liquidacion.importe} md={2} />
                <RTextField type="text" name="notas" label="Notas" actualizaCampo={actualizaCampo} value={liquidacion.notas} md={6} />                
            </Grid>
            <Grid container spacing={1} sx={{ marginBottom: '30px' }}>        
                <RTextField type="text" name="factura" label="Nº Factura" actualizaCampo={actualizaCampo} value={liquidacion.factura} md={2} />
                <RTextField type="text" name="rectificativa" label="Rectificativa" actualizaCampo={actualizaCampo} value={liquidacion.rectificativa} md={2} />
                { doc !== 0 &&
                <Grid item md={2}>    
                    <Button onClick={descargarDoc} color="secondary" variant="contained">Ver documento</Button>
                </Grid>
                }
                { doc !== 0 &&
                <Grid item md={3}>    
                    <Button onClick={borrarDoc} color="error" variant="contained">Borrar documento</Button>
                </Grid>
                }
            </Grid>
            {liquidacion.id !== '' &&
            <Grid container spacing={1} sx={{ marginBottom: '30px' }}> 
                <Grid item md={10}>
                    <FileUpload key={fileKey} title="" header="[Arrastra el documento que quieras subir]" leftLabel="o" rightLabel="para seleccionarlo" 
                        buttonLabel="Pulsa aquí" buttonRemoveLabel="Borrar" onFilesChange={handleFilesChange} onContextReady={(_context) => {}}/> 
                </Grid>  
                <Grid item md={2}>    
                    <Button onClick={uploadFiles} variant="contained" id="uploadButton">
                        Subir
                    </Button>
                </Grid> 
            </Grid> 
            }
        </form>
    );
}
