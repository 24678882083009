import React from 'react';
// @mui
import { Grid } from '@mui/material';
// components
import RTextField from '../../../componentes/forms/inputs/RTextField';
import RSelect from '../../../componentes/forms/inputs/RSelect';

// ----------------------------------------------------------------------

export default function RedForm({ showLoader, actualizaCampo, matricula, tutores }) {   
  React.useEffect(() => {
    showLoader(false); 
    // eslint-disable-next-line
  },[]);

  return (
    <form style={{ width: '100%'}}>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>        
        <RSelect name="confirmacionProveedor" label="Confirmación Proveedor" actualizaCampo={actualizaCampo} value={matricula.confirmacionProveedor} md={3}
            values={[{id:'NECESITA CONFIRMACIÓN',nombre:'[!] NECESITA CONFIRMACIÓN'},{id:'CONFIRMADO',nombre:'CONFIRMADO'}]}
            />
        <RTextField type="text" name="codigoAulaMadre" label="Cód. Aula Madre" actualizaCampo={actualizaCampo} value={matricula.codigoAulaMadre} md={2} />
        <RTextField type="text" name="codigoAulaAlumno" label="Cód Aula Alumno" actualizaCampo={actualizaCampo} value={matricula.codigoAulaAlumno} md={2} />
      </Grid>
      <Grid container spacing={1}>
        <RSelect name="idTutor" label="Tutor" actualizaCampo={actualizaCampo} value={matricula.idTutor} md={4}
          values={tutores}
        />
        <RTextField type="text" name="notas" label="Observaciones" actualizaCampo={actualizaCampo} value={matricula.notas} md={8} />
      </Grid>
    </form>
  );
}
