import React from 'react';
// material
import {Button, Card, CardHeader, CardContent, Divider} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Swal from 'sweetalert2';
// components
import Page from '../../componentes/Page';
import Iconify from '../../componentes/Iconify';
// mock
import RTabla from '../../componentes/tabla';
import CONFIG from '../../CONFIG.json';
import { buildBody, isEmpty, workWithLocalStorage, getUniqueKey } from '../../auxiliares/funciones';

import Alta from './alta';

// ----------------------------------------------------------------------

export default function Index(props) {
  const [lista, setLista] = React.useState([]);
  const [cargado, setCargado] = React.useState(false);
  const [key, setKey] = React.useState(getUniqueKey());

    const campos = ['id','centroformacion','redcomercial','comercial','asesoria','empresa','trabajador','fechaInicio','fechaFin','duracion','estadocontrato','planificacionHecha','usuarioalta','estado'];    
    const cabecera = ['ID','Centro Formación','Red Comercial','Comercial','Asesoria','Empresa','Trabajador','Fecha Inicio','Fecha Fin','Duración','Estado Contrato','Planificación','Usuario Alta','Estado'];
    const usuario = workWithLocalStorage('get','usuario',null);

    React.useEffect(() => {
      if(!cargado){
        const body = props.idTrabajador ? { id: props.idTrabajador} : {};
        const url = props.idTrabajador ? 'trabajador/contratos' : 'contratos';
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
        fetch(`${CONFIG.api}${url}`,{
                  method: 'POST',
                  body: bodyJson,
                  headers: {'Content-Type': 'application/json'}
          })
          .then(res => res.json())
          .then((result) => {
            if (result.error === 0 && !isEmpty(result.data)) {
              setLista(result.data);
            }
            setKey(getUniqueKey());
            // eslint-disable-next-line react/prop-types
            props.showLoader(false);            
            setCargado(true);
        })
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[cargado]);

    const altaContrato = (objeto) => {
      if(objeto !== null){
        // eslint-disable-next-line
        props.setTabs({ nombre: `Editar Contrato #${objeto.id}`, componente: Alta, params: {id: objeto.id}, visible: true })
      }else{
        // eslint-disable-next-line
        props.setTabs({ nombre: 'Alta Contrato', componente: Alta, params: {}, visible: true })
      }
    }

    const descargar = () =>{
        props.showLoader(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,{}))
        fetch(`${CONFIG.api}contratos/descarga`,{
            method: 'PUT',
            body: bodyJson,
            headers: {'Content-Type': "application/json"},
            responseType: "arraybuffer"
        })
            // .then((response) => {
            .then(res => res.blob())
            .then((blob) =>{
                if(blob.type !== 'application/json'){
                    const file = window.URL.createObjectURL(new Blob([blob]));
                    const fileLink = document.createElement('a');
                    fileLink.href = file;
                    fileLink.setAttribute('download', `listado_contratos.xlsx`);
                    fileLink.setAttribute('target', '_blank');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    fileLink.remove();
                }else{
                    Swal.fire({
                        title: 'No hay registros con ese filtro',
                        buttons: [{label: 'Ok'}]
                    })
                }
                // eslint-disable-next-line react/prop-types
                props.showLoader(false);
            })
    }

    const Actualiza = () =>{
      // eslint-disable-next-line react/prop-types
      props.showLoader(true); 
      // setCargado(false);
      setCargado(false)
    }

    const validaBorrar = (obj) => obj.activo === 1;
    const validaActivar = (obj) => obj.activo !== 1;

    const Activar = (obj) =>{
          // eslint-disable-next-line react/prop-types
          props.showLoader(true); 
          const body = {
            tabla: "contratos",
            activo: 1,
            usuario: usuario.id,
            id: obj.id
          }
          const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
          fetch(`${CONFIG.api}master/activo`,{
                    method: 'PUT',
                    body: bodyJson,
                    headers: {'Content-Type': 'application/json'}
            })
            .then(res => res.json())
            .then((result) => {
              let msg ='Contrato activado!';
              if (result.error === 0) {   
                setLista([]);        
                setCargado(false);
              }else{
                msg = result.errorMessage;
              }
              Swal.fire({
                title: msg,
                buttons: [{label: 'Ok'}]
              })
          })
    }

    const Borrar = (obj) =>{
        // eslint-disable-next-line react/prop-types
        props.showLoader(true); 
        const body = {
          tabla: "contratos",
          activo: 0,
          usuario: usuario.id,
          id: obj.id
        }
        const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
        fetch(`${CONFIG.api}master/activo`,{
                  method: 'PUT',
                  body: bodyJson,
                  headers: {'Content-Type': 'application/json'}
          })
          .then(res => res.json())
          .then((result) => {
            let msg ='Contrato desactivado!';
              if (result.error === 0) {    
                setLista([]);               
                setCargado(false);
              }else{
                msg = result.errorMessage;
              }
              Swal.fire({
                title: msg,
                buttons: [{label: 'Ok'}]
              })
        })
    }

    const theme = useTheme();
    const acciones = [
      {
        color:undefined,
        nombre:'Ver',
        icono:<Iconify icon="eva:edit-fill" style={{ fontSize: '20px' }} />,
        funcion: altaContrato,
        estilo: { backgroundColor: theme.palette.gradients.primary, color: theme.palette.common.white},
        condition: null
      },
      {
        color:'error',
        nombre:'Borrar',
        icono:<i />,
        funcion: Borrar,
        estilo: {},
        condition: validaBorrar
      },
      {
        color:'warning',
        nombre:'Activar',
        icono:<i />,
        funcion: Activar,
        estilo: {},
        condition: validaActivar
      }
    ]

  return (    
    <Page title="Contratos" key={lista.length}>
        <Card sx={{ maxWidth: '100% !important'}}>
          <CardHeader action={<>
                                <Button variant="contained" color="primary" sx={{ color: 'white', marginRight: '15px'}} to="#" startIcon={<Iconify icon="eva:plus-fill" />}
                                onClick={()=>altaContrato(null)}>
                                  Alta
                                </Button>
                                <Button variant="contained" color="success" sx={{ color: 'white', marginRight: '15px'}} to="#" startIcon={<Iconify icon="eva:refresh-fill" />}
                                onClick={()=>Actualiza()}>
                                  Actualizar
                                </Button>
                                <Button variant="contained" color="warning" sx={{ color: 'white'}} to="#" onClick={()=>descargar()}>Descargar</Button>
                              </>}
                  title="Contratos"
          />
          <Divider style={{ margin: '15px'}} />
          <CardContent>
            <RTabla key={key} lista={lista} campos={campos} cabecera={cabecera} acciones={acciones}/>
          </CardContent>
        </Card>
    </Page>
  );
}
