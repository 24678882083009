import React from 'react';
// @mui
import { Grid } from '@mui/material';
// components
import RTextField from '../../../componentes/forms/inputs/RTextField';
import RSelect from '../../../componentes/forms/inputs/RSelect';

// ----------------------------------------------------------------------

export default function AseForm({ showLoader, actualizaCampo, asesoria, provincias, comerciales }) {   

  React.useEffect(() => {
    showLoader(false); 
    // eslint-disable-next-line
  },[]);

  return (
    <form style={{ width: '100%'}}>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <RSelect name="idComercial" label="Comercial*" actualizaCampo={actualizaCampo} value={asesoria.idComercial} md={6}
          values={comerciales} searchable={1}/>
      </Grid>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <RTextField type="text" name="nombre" label="Nombre*" actualizaCampo={actualizaCampo} value={asesoria.nombre} md={6} />
        <RTextField type="text" name="cif" label="CIF*" actualizaCampo={actualizaCampo} value={asesoria.cif} md={2} />
        <RTextField type="text" name="telefono" label="Teléfono*" actualizaCampo={actualizaCampo} value={asesoria.telefono} md={2} />
      </Grid>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <RTextField type="text" name="direccion" label="Direccion" actualizaCampo={actualizaCampo} value={asesoria.direccion} md={5} />
        <RTextField type="text" name="cp" label="C.P." actualizaCampo={actualizaCampo} value={asesoria.cp} md={1} />
        <RTextField type="text" name="localidad" label="Localidad" actualizaCampo={actualizaCampo} value={asesoria.localidad} md={2} />
        <RSelect name="idProvincia" label="Provincia" actualizaCampo={actualizaCampo} value={asesoria.idProvincia} md={3}
          values={provincias} searchable={1}/>
      </Grid>
      <Grid container spacing={1}>
        <RTextField type="text" name="email" label="Email*" actualizaCampo={actualizaCampo} value={asesoria.email} md={5} />
        <RSelect name="tipoComision" label="TipoComisión*" actualizaCampo={actualizaCampo} value={asesoria.tipoComision} md={2}
          values={[{id:0,nombre:'Fijo'},{id:1,nombre:'Porcentaje'}]}/>
        <RTextField type="number" name="comision" label="Comisión*" actualizaCampo={actualizaCampo} value={asesoria.comision} md={1} />
      </Grid>
    </form>
  );
}
