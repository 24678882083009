import * as React from 'react';
// material
import { Tabs, Tab, Box} from '@mui/material';
// components
import Page from '../componentes/Page';

import Ausencias from './listados/ausencias';
import FinContrato from './listados/fincontrato';

// ----------------------------------------------------------------------
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`index-tabpanel-${index}`}
      aria-labelledby={`index-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `index-tab-${index}`,
    'aria-controls': `index-tabpanel-${index}`,
  };
}

export default function Index(props) {
  const [value, setValue] = React.useState(0);

  const handleChangeIndex = (event, newValue) => {
    setValue(newValue);
  };

  return (    
    <Page title="Inicio">
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChangeIndex}>
            <Tab label="Ausencias" {...a11yProps(0)} />
            <Tab label="Fin Contrato" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Ausencias showLoader={props.showLoader} setTabs={props.setTabs} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <FinContrato showLoader={props.showLoader} setTabs={props.setTabs} />
        </TabPanel>
      </Box>
    </Page>
  );
}
