import React from 'react';
// material
import { Card, CardContent, Divider, Button, CardHeader, Grid } from '@mui/material';
import Swal from 'sweetalert2';
// components
import Page from '../../componentes/Page';
import Iconify from '../../componentes/Iconify';
// mock
import RTabla from '../../componentes/tabla';
import CONFIG from '../../CONFIG.json';
import { buildBody, isEmpty, getUniqueKey, workWithLocalStorage } from '../../auxiliares/funciones';
import RDateField from '../../componentes/forms/inputs/RDateField';
import RSelect from '../../componentes/forms/inputs/RSelect';
import { getRedesComerciales } from '../../auxiliares/maestros';

// ----------------------------------------------------------------------

export default function Index(props) {
  const [lista, setLista] = React.useState([]);
  const [redes, setRedesComerciales] = React.useState([]);
  const [filtro, setFiltro] = React.useState({desde:'',hasta:'',id:0});
  const [key, setKey] = React.useState(getUniqueKey());
  const [cargado, setCargado] = React.useState(false);
  const usuario = workWithLocalStorage('get','usuario',null);  

  React.useEffect(() => {
    async function cargaInicial(){
        if(!cargado){         
            setRedesComerciales(await getRedesComerciales());
            if(filtro.id !== 0){                
                props.showLoader(true);  
                const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,{id:filtro.id}))
                fetch(`${CONFIG.api}liquidaciones/redes`,{
                        method: 'POST',
                        body: bodyJson,
                        headers: {'Content-Type': 'application/json'}
                })
                .then(res => res.json())
                .then((result) => {
                    if (result.error === 0 && !isEmpty(result.data)) {
                        setLista(result.data);
                    }else{
                        setLista([])
                    }                    
                    setKey(getUniqueKey());
                    // eslint-disable-next-line react/prop-types
                    props.showLoader(false);            
                    setCargado(true);
                })
            }else{                
                props.showLoader(false);  
            }
        }
    }
    cargaInicial();
  // eslint-disable-next-line
  },[cargado]);

    const actualizaCampo = (input) =>{
        const nombre = input.target.name
        const valor = input.target.value
        const filtroA = filtro;
        filtroA[nombre] = valor
        setFiltro(filtroA);
        if(nombre === 'id'){
            setCargado(false);
        }
    }

    const campos = ['notas','fechaAlta','usuario'];    
    const cabecera = ['Descripción','Fecha Alta','Usuario'];
  
    const Borrar = (obj) =>{
      // eslint-disable-next-line react/prop-types
      props.showLoader(true); 
      const body = {
        carpeta: `liquidaciones/redes`,
        id: obj.id,
        idReferencia: obj.idReferencia,
      }
      const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
      fetch(`${CONFIG.api}docs/borrar`,{
                method: 'PUT',
                body: bodyJson,
                headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then((result) => {
            let msg ='Liquidación borrada!';
            if (result.error === 0) {   
                setCargado(false);          
            }else{
                msg = result.errorMessage;
                setCargado(false); 
            }
            props.showLoader(false); 
            Swal.fire({
              title: msg,
              buttons: [{label: 'Ok'}]
            })
      })
  }
  
  const descargar = (obj) =>{
      // eslint-disable-next-line react/prop-types
      props.showLoader(true);   
      console.log(obj)
      fetch(`${CONFIG.api}docs/descarga?carpeta=liquidaciones/redes&id=${obj.id}&idReferencia=${obj.idReferencia}&API_TOKEN=${CONFIG.TOKEN}`,{
              method: 'GET',
              headers: {'Content-Type': 'application/json'},
              responseType: 'blob'
      })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        window.open(url,'_blank')
        props.showLoader(false);  
      })
  }

    const Liquidar = () =>{
        if(filtro.desde !== '' && filtro.hasta !== '' && filtro.id !== 0){            
            props.showLoader(true); 
            const body = filtro;
            body.usuario = usuario.id
            // eslint-disable-next-line react-hooks/exhaustive-deps
            const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
            fetch(`${CONFIG.api}liquidaciones/redes`,{
                    method: 'PUT',
                    body: bodyJson,
                    headers: {'Content-Type': 'application/json'}
            })
            .then(res => res.json())
            .then((result) => {
                let msg = '';
                if (result.error === 0) {
                    setCargado(false);
                    msg = `Liquidadas ${result.data.data.num} facturas`
                }else{
                    msg = result.errorMessage;
                }
                Swal.fire({
                title: msg,
                buttons: [{label: 'Ok'}]
                })
                // eslint-disable-next-line react/prop-types
                props.showLoader(false); 
            })
        }else if(filtro.desde !== '' && filtro.hasta !== '' && filtro.id === 0){  
            props.showLoader(true); 
            const body = filtro;
            body.usuario = usuario.id
            // eslint-disable-next-line react-hooks/exhaustive-deps
            const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
            fetch(`${CONFIG.api}liquidaciones/redes/todas`,{
                    method: 'PUT',
                    body: bodyJson,
                    headers: {'Content-Type': "application/json"}, 
                    responseType: "arraybuffer"
            })
            // .then((response) => {
            .then(res => res.blob())
            .then((blob) =>{
                if(blob.type !== 'application/json'){
                    const file = window.URL.createObjectURL(new Blob([blob]));
                    const fileLink = document.createElement('a');
                    fileLink.href = file;
                    fileLink.setAttribute('download', `${filtro.desde}_${filtro.hasta}.xlsx`);
                    fileLink.setAttribute('target', '_blank');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    fileLink.remove();
                }else{
                    Swal.fire({
                        title: 'No hay registros con ese filtro',
                        buttons: [{label: 'Ok'}]
                        })
                }
                // eslint-disable-next-line react/prop-types
                props.showLoader(false); 
            })
        }else{
            Swal.fire({
                title: 'Selecciona desde que fecha hasta que fecha de fecha de facturación',
                buttons: [{label: 'Ok'}]
            })
        }
    }

    const acciones = [
        {
            color:'success',
            nombre:'Descargar',
            icono:<i />,
            funcion: descargar,
            estilo: {},
            condition: null
            },{
            color:'error',
            nombre:'Borrar',
            icono:<i />,
            funcion: Borrar,
            estilo: {},
            condition: null
        }
    ]



  return (    
    <Page key={lista.length} sx={{ width: '100% !important'}}>
        <Card  sx={{ maxWidth: '100% !important'}}>
            <CardHeader title="Liquidar Red"/>
          <Divider style={{ margin: '15px'}} />
          <CardContent>
            <form style={{ width: '100%'}}>
                <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
                    <RSelect name="id" label="Red Comercial" actualizaCampo={actualizaCampo} value={filtro.id} md={6} values={redes} searchable={1}/>
                    <RDateField type="text" name="desde" label="Desde F. Cobro" actualizaCampo={actualizaCampo} value={filtro.desde} md={2} />
                    <RDateField type="text" name="hasta" label="Hasta F. Cobro" actualizaCampo={actualizaCampo} value={filtro.hasta} md={2} />
                    {filtro.id !== '' &&
                    <Grid item md={2} sm={2}>
                        <Button variant="contained" color="success" sx={{ color: 'white'}} to="#" startIcon={<Iconify icon="eva:refresh-fill" />}
                            onClick={()=>Liquidar()}>Liquidar</Button>
                    </Grid>
                    }
                </Grid>
            </form>
          </CardContent>
          <Divider style={{ margin: '15px'}} />
          <CardContent>
            <RTabla key={key} lista={lista} campos={campos} cabecera={cabecera} acciones={acciones}/>
          </CardContent>
        </Card>
    </Page>
  );
}
