import React from 'react';
// @mui
import { Grid, Divider } from '@mui/material';
// components
import RTextField from '../../../componentes/forms/inputs/RTextField';
import RDateField from '../../../componentes/forms/inputs/RDateField';
import RSelect from '../../../componentes/forms/inputs/RSelect';
import RCheck from '../../../componentes/forms/inputs/RCheck';

// ----------------------------------------------------------------------

export default function AseForm({ showLoader, actualizaCampo, trabajador, provincias, empresas }) {   

  React.useEffect(() => {
    showLoader(false); 
    // eslint-disable-next-line
  },[]);

  return (
    <form style={{ width: '100%'}}>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <RSelect name="idEmpresa" label="Empresa*" actualizaCampo={actualizaCampo} value={trabajador.idEmpresa} md={6}
          values={empresas} searchable={1}/>
      </Grid>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <RTextField type="text" name="nombre" label="Nombre*" actualizaCampo={actualizaCampo} value={trabajador.nombre} md={3} />
        <RTextField type="text" name="apellido1" label="1er Apellido*" actualizaCampo={actualizaCampo} value={trabajador.apellido1} md={3} />
        <RTextField type="text" name="apellido2" label="2do Apellido" actualizaCampo={actualizaCampo} value={trabajador.apellido2} md={3} />
        <RSelect name="sexo" label="Sexo" actualizaCampo={actualizaCampo} value={trabajador.sexo} md={2}
          values={[{id:1,nombre:'Hombre'},{id:0,nombre:'Mujer'}]}/>
      </Grid>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <RTextField type="text" name="nacionalidad" label="Nacionalidad" actualizaCampo={actualizaCampo} value={trabajador.nacionalidad} md={2} />
        <RTextField type="text" name="nif" label="NIF*" actualizaCampo={actualizaCampo} value={trabajador.nif} md={2} />
        <RDateField name="fechaNacimiento" label="Fecha Nac.*" actualizaCampo={actualizaCampo} value={trabajador.fechaNacimiento} md={2} />
        <RTextField type="text" name="numSegSocial" label="Nº Seg. Social" actualizaCampo={actualizaCampo} value={trabajador.numSegSocial} md={2} />
      </Grid>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <RTextField type="text" name="direccion" label="Direccion" actualizaCampo={actualizaCampo} value={trabajador.direccion} md={5} />
        <RTextField type="text" name="cp" label="C.P." actualizaCampo={actualizaCampo} value={trabajador.cp} md={1} />
        <RTextField type="text" name="localidad" label="Localidad" actualizaCampo={actualizaCampo} value={trabajador.localidad} md={2} />
        <RSelect name="idProvincia" label="Provincia" actualizaCampo={actualizaCampo} value={trabajador.idProvincia} md={3}
          values={provincias} searchable={1}/>
      </Grid>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <RTextField type="text" name="telefono" label="Teléfono" actualizaCampo={actualizaCampo} value={trabajador.telefono} md={2} />
        <RTextField type="text" name="email" label="Email" actualizaCampo={actualizaCampo} value={trabajador.email} md={5} />
      </Grid>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <RTextField type="text" name="coordinador" label="Coordinador" actualizaCampo={actualizaCampo} value={trabajador.coordinador} md={4} />
        <RTextField type="text" name="coordinadorMail" label="Coord. Email" actualizaCampo={actualizaCampo} value={trabajador.coordinadorMail} md={5} />
      </Grid>
      <Divider style={{ margin: '15px', marginBottom: '30px'}} />
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <RTextField type="text" name="estudios" label="Estudios terminados" actualizaCampo={actualizaCampo} value={trabajador.estudios} md={9} />
        <RTextField type="text" name="cuentaBancaria" label="Cuenta Bancaria" actualizaCampo={actualizaCampo} value={trabajador.cuentaBancaria} md={3} />
      </Grid>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <RCheck name="inscritoDemandante" label="Inscrito como demandante" actualizaCampo={actualizaCampo} value={trabajador.inscritoDemandante} md={3} />
        <RCheck name="trabajadorExclusion" label="Trabajador en exclusión social" actualizaCampo={actualizaCampo} value={trabajador.trabajadorExclusion} md={3} />
        <RCheck name="inscritoGarantia" label="Inscrito en garantía" actualizaCampo={actualizaCampo} value={trabajador.inscritoGarantia} md={3} />
        <RCheck name="discapacidad" label="Discapacidad (Sin límite de edad)" actualizaCampo={actualizaCampo} value={trabajador.discapacidad} md={3} />
      </Grid>
    </form>
  );
}
