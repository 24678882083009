import React from 'react';
// @mui
import { Grid } from '@mui/material';
// components
import RSelect from '../../../componentes/forms/inputs/RSelect';
import RTextField from '../../../componentes/forms/inputs/RTextField';

// ----------------------------------------------------------------------

export default function FestForm({ showLoader, actualizaCampo, serie, anyos, tipos }) {   
  React.useEffect(() => {
    showLoader(false); 
    // eslint-disable-next-line
  },[]);

  return (
    <form style={{ width: '100%'}}>
        <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
            <RTextField type="text" name="serie" label="Serie*" actualizaCampo={actualizaCampo} value={serie.serie} md={3} />
            <RSelect name="anyo" label="Año*" actualizaCampo={actualizaCampo} value={serie.anyo} md={3} values={anyos}/>
            <RSelect name="tipo" label="Tipo*" actualizaCampo={actualizaCampo} value={serie.tipo} md={3} values={tipos}/>
        </Grid>
        <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
            <RTextField type="text" name="bccMail" label="Mail en Copia*" actualizaCampo={actualizaCampo} value={serie.bccMail} md={6} />
        </Grid>
    </form>
  );
}
