import React from 'react';
// @mui
import { Grid, Button } from '@mui/material';
import FileUpload from "react-mui-fileuploader";
import Swal from 'sweetalert2';

import { buildBody, isEmpty, getUniqueKey, workWithLocalStorage } from '../../../auxiliares/funciones';
import { getDocumentos } from '../../../auxiliares/clases/contratos';
import CONFIG from '../../../CONFIG.json';
import RTabla from '../../../componentes/tabla';

// ----------------------------------------------------------------------

export default function EstadosForm({ showLoader, contrato }) {   

  const [doclist, setDoclist] = React.useState([]);
  const [cargado, setCargado] = React.useState(false);
  const [key, setKey] = React.useState(getUniqueKey());
  const [fileKey, setFileKey] = React.useState(getUniqueKey());
  const [filesForm, setFiles] = React.useState([]);
  const usuario = workWithLocalStorage('get','usuario',null);  

  React.useEffect(() => {
    async function cargaInicial(){
        if(!cargado){         
            setDoclist(await getDocumentos(contrato.id));            
            setKey(getUniqueKey());  
            setCargado(true);
        }
    }
    cargaInicial();
  // eslint-disable-next-line
  },[cargado]);

  const handleFilesChange = (files) => {
    // Update chosen files
    setFiles([...files]);
  };

  const uploadFiles = () =>{    
    // Create a form and post it to server
    const formData = new FormData();
    filesForm.forEach((file) => formData.append("files", file,file.name));

    /* fetch("/file/upload", {
      method: "POST",
      body: formData
    }); */
    if(!isEmpty(filesForm)){ 
        // eslint-disable-next-line react/prop-types
        // showLoader(true); 
        const body = {};
        body.id = contrato.id;
        body.idUsuarioAlta = usuario.id;
        body.carpeta = 'contratos';
        const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
        formData.append("str", bodyJson);
        fetch(`${CONFIG.api}docs/subir`,{
                method: 'POST',
                body: formData
        })
        .then(res => res.json())
        .then((result) => {
            let msg ='Documento(s) grabado(s)!';
            if (result.error === 0) {   
                setCargado(false);   
                setFiles([]);
                setFileKey(getUniqueKey());
            }else{
                msg = result.errorMessage;
                setCargado(false); 
            }
            showLoader(false); 
            Swal.fire({
            title: msg,
            buttons: [{label: 'Ok'}]
            })
        }) 
    }else{
        Swal.fire({
            title: 'Debes seleccionar al menos un documento',
            buttons: [{label: 'Ok'}]
        })
    }
  }

  /* ***************** TABLA ************************** */
  const campos = ['nombre','notas','fechaAlta','usuario'];    
  const cabecera = ['Nombre','Notas','Fecha Alta','Usuario'];

  const BorrarDoc = (obj) =>{
    // eslint-disable-next-line react/prop-types
    showLoader(true); 
    const body = {
      carpeta: "contratos",
      id: obj.id,
      idReferencia: obj.idReferencia,
    }
    const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
    fetch(`${CONFIG.api}docs/borrar`,{
              method: 'PUT',
              body: bodyJson,
              headers: {'Content-Type': 'application/json'}
      })
      .then(res => res.json())
      .then((result) => {
          let msg ='Documento borrado!';
          if (result.error === 0) {   
              setCargado(false);          
          }else{
              msg = result.errorMessage;
              setCargado(false); 
          }
          showLoader(false); 
          Swal.fire({
            title: msg,
            buttons: [{label: 'Ok'}]
          })
    })
}

const descargarDoc = (obj) =>{
    // eslint-disable-next-line react/prop-types
    showLoader(true); 
    const body = {
      carpeta: "contratos",
      id: obj.id,
      idReferencia: obj.idReferencia,
    }

    fetch(`${CONFIG.api}docs/descarga?carpeta=contratos&id=${obj.id}&idReferencia=${obj.idReferencia}&API_TOKEN=${CONFIG.TOKEN}`,{
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
            responseType: 'blob'
    })
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      window.open(url,'_blank')
      showLoader(false); 
    })
    /* .then(response => {
      showLoader(false); 
      try{
        fileDownload(response.data, obj.nombre);
      }catch(error){
        console.log(error)
          Swal.fire({
              title: 'No se pudo descargar el fichero',
              buttons: [{label: 'Ok'}]
          })
      }
  }); */
}

const editarNotas = async (obj) =>{

  const { value: notas } = await Swal.fire({
    title: 'Escribe la anotación',
    input: 'text',
    showCancelButton: true,
  })

  // eslint-disable-next-line react/prop-types
  showLoader(true); 
  const body = {
    carpeta: "contratos",
    usuario: usuario.id,
    id: obj.id,
    idReferencia: obj.idReferencia,
    notas
  }
  const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
  fetch(`${CONFIG.api}docs/notas`,{
            method: 'PUT',
            body: bodyJson,
            headers: {'Content-Type': 'application/json'}
    })
    .then(res => res.json())
    .then((result) => {
      let msg ='Anotación guardada!';
      if (result.error === 0) {      
        setCargado(false);
      }else{
        msg = result.errorMessage;
      }
      showLoader(false); 
      Swal.fire({
        title: msg,
        buttons: [{label: 'Ok'}]
      })
  })
}

  const acciones = [{
    color:'info',
    nombre:'Anotaciones',
    icono:<i />,
    funcion: editarNotas,
    estilo: {},
    condition: null
  },{
    color:'success',
    nombre:'Descargar',
    icono:<i />,
    funcion: descargarDoc,
    estilo: {},
    condition: null
  },{
    color:'error',
    nombre:'Borrar',
    icono:<i />,
    funcion: BorrarDoc,
    estilo: {},
    condition: null
  }]
    /* eslint no-unused-vars: "off" */
  return (
    <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <Grid item md={10}>
            <FileUpload key={fileKey} title="" header="[Arrastra los documentos que quieras subir]" leftLabel="o" rightLabel="para seleccionarlos" 
                buttonLabel="Pulsa aquí" buttonRemoveLabel="Borrar todos" multiFile onFilesChange={handleFilesChange} onContextReady={(_context) => {}}/> 
        </Grid>    
        <Grid item md={2}>    
            <Button onClick={uploadFiles} variant="contained" id="uploadButton">
                Subir
            </Button>
        </Grid>        
        {!isEmpty(doclist) &&
        <Grid item md={12} sx={{ marginTop: '20px' }}>
            <RTabla key={key} lista={doclist} campos={campos} cabecera={cabecera} acciones={acciones}/>
        </Grid>
        }
    </Grid>
  );
}
