import React from 'react';
// @mui
import { Grid } from '@mui/material';
// components
import RTextField from '../../../componentes/forms/inputs/RTextField';
import RSelect from '../../../componentes/forms/inputs/RSelect';

// ----------------------------------------------------------------------

export default function RedForm({ showLoader, actualizaCampo, red }) {   
  React.useEffect(() => {
    showLoader(false); 
    // eslint-disable-next-line
  },[]);

  return (
    <form style={{ width: '100%'}}>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <RTextField type="text" name="nombre" label="Nombre*" actualizaCampo={actualizaCampo} value={red.nombre} md={6} />
      </Grid>
      <Grid container spacing={1}>
        <RTextField type="text" name="telefono" label="Teléfono*" actualizaCampo={actualizaCampo} value={red.telefono} md={2} />
        <RSelect name="tipoComision" label="TipoComisión*" actualizaCampo={actualizaCampo} value={red.tipoComision} md={2}
          values={[{id:0,nombre:'Fijo'},{id:1,nombre:'Porcentaje'}]}
        />
        <RTextField type="number" name="comision" label="Comisión*" actualizaCampo={actualizaCampo} value={red.comision} md={1} />
        <RTextField type="number" name="iban" label="IBAN" actualizaCampo={actualizaCampo} value={red.iban} md={4} />
      </Grid>
    </form>
  );
}
