import React from 'react';
// components
import Divider from "@mui/material/Divider";
import { Button, Card, CardHeader, CardContent, Modal } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Swal from 'sweetalert2';
import CloseIcon from '@mui/icons-material/Close';

import Page from '../../componentes/Page';
import Iconify from '../../componentes/Iconify';
import RTabla from '../../componentes/tabla';
import CONFIG from '../../CONFIG.json';
import { buildBody, isEmpty, getUniqueKey, workWithLocalStorage, compruebaForm } from '../../auxiliares/funciones';
import RedForm from './form/redcomform';
import ComForm from './form/comform';

// ----------------------------------------------------------------------
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    minWidth: '750px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

export default function AltaForm(props) {
    const usuario = workWithLocalStorage('get','usuario',null);
    const Form = {
        nombre: '',
        telefono: '',
        comision: null,
        tipoComision:null,
        iban:'',
        activo: 1,
      };
    const obligatorios = ['nombre','telefono','comision','tipoComision'];

    const [red, setRed] = React.useState(Form);
    const [lista, setLista] = React.useState([]);
    const [cargado, setCargado] = React.useState(false);
    const [id,setId] = React.useState(props.params.id ? props.params.id : '0');

    React.useEffect(() => {
        if(!cargado){
            if(id !== '0'){                
            // eslint-disable-next-line
            const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,{ id:id }))
            fetch(`${CONFIG.api}red/detalle`,{
                        method: 'POST',
                        body: bodyJson,
                        headers: {'Content-Type': 'application/json'}
                })
                .then(res => res.json())
                .then((result) => {
                if (result.error === 0 && !isEmpty(result.data)) {
                    setRed(result.data);
                    fetch(`${CONFIG.api}red/comerciales`,{
                        method: 'POST',
                        body: bodyJson,
                        headers: {'Content-Type': 'application/json'}
                    })
                    .then(res => res.json())
                    .then((result) => {
                        if (result.error === 0 && !isEmpty(result.data)) {
                            setLista(result.data);
                        }
                        // eslint-disable-next-line
                        props.showLoader(false);            
                        setCargado(true);
                    })
                }
            })
            }else{
                setCargado(true);
            }
        }
      // eslint-disable-next-line
      },[cargado]);

    const Grabar = () =>{
        const valido = compruebaForm(Form,red,obligatorios);
        if(valido){
            // eslint-disable-next-line react/prop-types
            props.showLoader(true); 
            const body = red;
            body.idUsuarioAlta = usuario.id;
            body.idUsuarioMod = usuario.id;
            const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
            fetch(`${CONFIG.api}red/detalle`,{
                    method: 'PUT',
                    body: bodyJson,
                    headers: {'Content-Type': 'application/json'}
            })
            .then(res => res.json())
            .then((result) => {
                let msg ='Red comercial grabada!';
                if (result.error === 0) {   
                    setId(result.data.id);
                    setLista([]);
                    document.getElementById(`simple-tab-${props.index}`).getElementsByTagName( 'span' )[0].textContent = `Editar Red #${result.data.id} `;
                    setCargado(false);
                }else{
                    props.showLoader(false); 
                    msg = result.errorMessage;
                }
                Swal.fire({
                title: msg,
                buttons: [{label: 'Ok'}]
                })
            })
        }else{
            Swal.fire({
                title: 'Revisa los campos con *',
                buttons: [{label: 'Ok'}]
            })
        }
    }

    const Nuevo = () =>{
        setRed(Form);
        setId('0')
        document.getElementById(`simple-tab-${props.index}`).getElementsByTagName( 'span' )[0].textContent = 'Alta Red ';
    }
    
    const actualizaCampo = (input) =>{
        const nombre = input.target.name
        const valor = input.target.value
        const redA = red;
        redA[nombre] = valor
        setRed(redA);
    }

    const Activar = () =>{
        // eslint-disable-next-line react/prop-types
        props.showLoader(true); 
        const body = {
          tabla: "redes_comerciales",
          activo: 1,
          usuario: usuario.id,
          id: red.id
        }
        const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
        fetch(`${CONFIG.api}master/activo`,{
                  method: 'PUT',
                  body: bodyJson,
                  headers: {'Content-Type': 'application/json'}
          })
          .then(res => res.json())
          .then((result) => {
            let msg ='Red comercial activada!';
            if (result.error === 0) {   
                setCargado(false);
            }else{
              props.showLoader(false); 
              msg = result.errorMessage;
            }
            Swal.fire({
              title: msg,
              buttons: [{label: 'Ok'}]
            })
        })
    }

    const Borrar = () =>{
        // eslint-disable-next-line react/prop-types
        props.showLoader(true); 
        const body = {
            tabla: "redes_comerciales",
            activo: 0,
            usuario: usuario.id,
            id: red.id
        }
        const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
        fetch(`${CONFIG.api}master/activo`,{
                    method: 'PUT',
                    body: bodyJson,
                    headers: {'Content-Type': 'application/json'}
            })
            .then(res => res.json())
            .then((result) => {
                let msg ='Red comercial desactivada!';
                if (result.error === 0) {   
                    setCargado(false);
                }else{
                    props.showLoader(false); 
                    msg = result.errorMessage;
                }
                props.showLoader(false); 
                Swal.fire({
                title: msg,
                buttons: [{label: 'Ok'}]
                })
        })
    }
    // INI FUNCIONES DE LOS COMERCIALES ----------------------------------------
    const campos = ['id','nombre','telefono','activo'];    
    const cabecera = ['ID','Nombre','Teléfono','Estado'];

    const FormCom = {
        nombre: '',
        telefono: '',
        idRed: red.id,
        activo: 1,
      };
    const obligatorios2 = ['nombre','telefono'];

    const validaBorrar = (obj) => obj.activo === 1;
    const validaActivar = (obj) => obj.activo !== 1;
    const [modalOpen, setModalOpen] = React.useState(false);
    const [comercial, setComercial] = React.useState(FormCom);
    const [nombreModal, setNombreModal] = React.useState('');
    const cierraModal = () => setModalOpen(false);
    const abreModal = () => setModalOpen(true);

    const ActivarCom = (obj) =>{
        // eslint-disable-next-line react/prop-types
        props.showLoader(true); 
        const body = {
          tabla: "comerciales",
          activo: 1,
          usuario: usuario.id,
          id: obj.id
        }
        const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
        fetch(`${CONFIG.api}master/activo`,{
                  method: 'PUT',
                  body: bodyJson,
                  headers: {'Content-Type': 'application/json'}
          })
          .then(res => res.json())
          .then((result) => {
            let msg ='Comercial activado!';
            if (result.error === 0) {   
              setLista([]);        
              setCargado(false);
            }else{
              msg = result.errorMessage;
            }
            Swal.fire({
              title: msg,
              buttons: [{label: 'Ok'}]
            })
        })
    }

    const BorrarCom = (obj) =>{
        // eslint-disable-next-line react/prop-types
        props.showLoader(true); 
        const body = {
            tabla: "comerciales",
            activo: 0,
            usuario: usuario.id,
            id: obj.id
        }
        const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
        fetch(`${CONFIG.api}master/activo`,{
                    method: 'PUT',
                    body: bodyJson,
                    headers: {'Content-Type': 'application/json'}
            })
            .then(res => res.json())
            .then((result) => {
            let msg ='Comercial desactivado!';
                if (result.error === 0) {    
                    setLista([]);               
                    setCargado(false);
                }else{
                    msg = result.errorMessage;
                }
                Swal.fire({
                    title: msg,
                    buttons: [{label: 'Ok'}]
                })
        })
    }

    const NuevoCom = (obj) =>{
        setComercial(obj === null ? FormCom : obj);
        setNombreModal(obj === null ? 'Alta Comercial' : `Editar Comercial #${obj.id}`);
        abreModal();
    }

    const actualizaCampoCom = (input) =>{
        const nombre = input.target.name
        const valor = input.target.value
        const comA = comercial;
        comA[nombre] = valor
        setComercial(comA);
    }

    const GrabarCom = () =>{
        const valido = compruebaForm(FormCom,comercial,obligatorios2);
        if(valido){
            // eslint-disable-next-line react/prop-types
            props.showLoader(true); 
            const body = comercial;
            body.idUsuarioAlta = usuario.id;
            body.idUsuarioMod = usuario.id;
            const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
            fetch(`${CONFIG.api}red/comercial`,{
                    method: 'PUT',
                    body: bodyJson,
                    headers: {'Content-Type': 'application/json'}
            })
            .then(res => res.json())
            .then((result) => {
                let msg ='Comercial grabado!';
                if (result.error === 0) { 
                    cierraModal();  
                    setCargado(false);
                }else{
                    props.showLoader(false); 
                    msg = result.errorMessage;
                }
                Swal.fire({
                    title: msg,
                    buttons: [{label: 'Ok'}]
                })
            })
        }else{
            Swal.fire({
                title: 'Revisa los campos con *',
                buttons: [{label: 'Ok'}]
            })
        }
    }

    const theme = useTheme();
    const acciones = [
        {
          color:undefined,
          nombre:'Ver',
          icono:<Iconify icon="eva:edit-fill" style={{ fontSize: '20px' }} />,
          funcion: NuevoCom,
          estilo: { backgroundColor: theme.palette.gradients.primary, color: theme.palette.common.white},
          condition: null
        },
        {
          color:'error',
          nombre:'Borrar',
          icono:<i />,
          funcion: BorrarCom,
          estilo: {},
          condition: validaBorrar
        },
        {
          color:'warning',
          nombre:'Activar',
          icono:<i />,
          funcion: ActivarCom,
          estilo: {},
          condition: validaActivar
        }
      ]
    // FIN FUNCIONES DE LOS COMERCIALES ----------------------------------------
    return (
        <Page title="Alta Red" key={getUniqueKey()}>
            <Card  sx={{ maxWidth: '100% !important'}}>
                <CardHeader action={<>
                                    <Button variant="contained" color="success" sx={{ color: 'white', marginRight: '15px'}} to="#" startIcon={<Iconify icon="eva:plus-fill" />}
                                    onClick={()=>Grabar(null)}>
                                        Grabar
                                    </Button>
                                    <Button variant="contained" color="primary" sx={{ color: 'white', marginRight: '15px'}} to="#" startIcon={<Iconify icon="eva:edit-outline" />}
                                    onClick={()=>Nuevo()}>
                                        Nuevo
                                    </Button>
                                    {red.id &&
                                    (red.activo === 1 ?
                                    <Button variant="contained" color="error" sx={{ color: 'white'}} to="#" startIcon={<Iconify icon="eva:trash-2-outline" />}
                                    onClick={()=>Borrar()}>
                                        Borrar
                                    </Button>
                                    : <Button variant="contained" color="warning" sx={{ color: 'white'}} to="#" startIcon={<Iconify icon="eva:refresh-fill" />}
                                    onClick={()=>Activar()}>
                                        Activar
                                    </Button>)
                                    }
                                    </>}
                        title={id === '0' ? "Alta Red" : `Editar Red #${id}`}
                />
                <Divider style={{ margin: '15px'}} />
                <CardContent>
                    <RedForm showLoader={props.showLoader} red={red} actualizaCampo={actualizaCampo}/>
                </CardContent>
                {red.id && <Divider style={{ margin: '15px'}} />}
                {red.id && <CardHeader title='Comerciales'
                 action={<Button variant="contained" color="primary" sx={{ color: 'white', marginRight: '15px'}} to="#" startIcon={<Iconify icon="eva:edit-outline" />}
                 onClick={()=>NuevoCom(null)}>
                     Nuevo
                 </Button>}/>}
                {red.id && 
                <CardContent>
                    <RTabla key={getUniqueKey()} lista={lista} campos={campos} cabecera={cabecera} acciones={acciones}/>
                </CardContent>
                }
            </Card>
            <Modal open={modalOpen} onClose={cierraModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Card sx={style}>
                    <CardHeader title={nombreModal}
                    action={<>
                        <Button variant="contained" color="primary" sx={{ color: 'white', marginRight: '15px'}} to="#" startIcon={<Iconify icon="eva:edit-outline" />}
                        onClick={()=>GrabarCom()}>
                            Grabar
                        </Button>
                        <CloseIcon fontSize="inherit" onClick={() => cierraModal() }/>
                    </>}/>
                    <Divider style={{ margin: '15px'}} />
                    <CardContent>
                        <ComForm showLoader={props.showLoader} comercial={comercial} actualizaCampo={actualizaCampoCom}/>
                    </CardContent>
                </Card>
            </Modal>
        </Page>
    );
}
