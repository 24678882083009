import React from 'react';
// @mui
import { Grid, Button } from '@mui/material';
import Swal from 'sweetalert2';
// components
import Iconify from '../../../componentes/Iconify';
import RDateField from '../../../componentes/forms/inputs/RDateField';
import RSelect from '../../../componentes/forms/inputs/RSelect';
import RTextField from '../../../componentes/forms/inputs/RTextField';

import { buildBody, isEmpty, getUniqueKey, workWithLocalStorage, compruebaForm } from '../../../auxiliares/funciones';
import { getContratosEstados } from '../../../auxiliares/maestros';
import { getEstados } from '../../../auxiliares/clases/contratos';
import CONFIG from '../../../CONFIG.json';
import RTabla from '../../../componentes/tabla';

// ----------------------------------------------------------------------

export default function EstadosForm({ showLoader, contrato }) {   

  const Form = { idEstado:0,fecha:'',notas:'', fechaInicio:'',duracion:0,fechaFin:'',fechaFinOriginal:'' };
  const [estado, setEstado] = React.useState(Form);
  const [estados, setEstados] = React.useState([]);
  const [estadosHist, setEstadosHist] = React.useState([]);
  const [cargado, setCargado] = React.useState(false);
  const [key, setKey] = React.useState(getUniqueKey());
  const [keyF, setKeyF] = React.useState(getUniqueKey());
  const usuario = workWithLocalStorage('get','usuario',null);  
  const obligatorios = ['idEstado','fecha'];  
  const obligatoriosPro = ['idEstado','fecha','fechaInicio','duracion','fechaFin'];

  React.useEffect(() => {
    async function cargaInicial(){
        if(!cargado){         
            setEstados(await getContratosEstados());
            setEstadosHist(await getEstados(contrato.id));
            setCargado(true);
        }
    }
    cargaInicial();
  // eslint-disable-next-line
  },[cargado]);

  const actualizaCampo = (input) =>{
    const nombre = input.target.name
    const valor = input.target.value
    const estadoA = estado;
    estadoA[nombre] = valor
    setEstado(estadoA);
    if(nombre === 'idEstado'){
      setKeyF(getUniqueKey());
    }
  }

  const Grabar = () =>{
    let valido = false;
    if(estado.idEstado >= 5 && estado.idEstado <= 11){
      valido = compruebaForm(Form,estado,obligatoriosPro);
      if(valido){
        estado.fechaFinOriginal=estado.fechaFin;
      }
    }else{
      valido = compruebaForm(Form,estado,obligatorios);
    }
    if(valido){
        // eslint-disable-next-line react/prop-types
        showLoader(true); 
        const body = estado;
        body.idContrato = contrato.id;
        body.idUsuarioAlta = usuario.id;
        const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
        fetch(`${CONFIG.api}contrato/estado`,{
                method: 'PUT',
                body: bodyJson,
                headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then((result) => {
            let msg ='Estado grabado!';
            if (result.error === 0) {   
                setCargado(false);
                setKey(getUniqueKey());            
            }else{
                msg = result.errorMessage;
            }
            showLoader(false); 
            Swal.fire({
            title: msg,
            buttons: [{label: 'Ok'}]
            })
        })
    }else{
        Swal.fire({
            title: 'Revisa los campos con *',
            buttons: [{label: 'Ok'}]
        })
    }
  }

  /* ***************** TABLA ************************** */
  const campos = ['fecha','estado','fechaInicio','duracion', 'horasF','fechaFin','fechaFinOriginal','notas','usuario'];
  const cabecera = ['Fecha','Estado','Fecha Inicio','Duración', 'H. Form','Fecha Fin','Fecha Fin Orig.','Notas','Usuario'];

  const BorrarEstado = (obj) =>{
    // eslint-disable-next-line react/prop-types
    showLoader(true); 
    const body = {
      tabla: "contratos_historico",
      activo: 0,
      usuario: usuario.id,
      id: obj.id
    }
    const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
    fetch(`${CONFIG.api}master/activo`,{
              method: 'PUT',
              body: bodyJson,
              headers: {'Content-Type': 'application/json'}
      })
      .then(res => res.json())
      .then((result) => {
        let msg ='Estado borrado!';
          if (result.error === 0) {      
            setCargado(false);
            setKey(getUniqueKey());
          }else{
            msg = result.errorMessage;
          }
          showLoader(false);
          Swal.fire({
            title: msg,
            buttons: [{label: 'Ok'}]
          })
    })
}

  const acciones = [{
      color:'error',
      nombre:'Borrar',
      icono:<i />,
      funcion: BorrarEstado,
      estilo: {},
      condition: null
    }]

  return (
    <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <Grid item md={12}>
            <form style={{ width: '100%'}} key={keyF} >
                <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
                    <RSelect name="idEstado" label="Estado*" actualizaCampo={actualizaCampo} value={estado.idEstado} md={3}
                    values={estados}/>
                    <RDateField name="fecha" label="Fecha*" actualizaCampo={actualizaCampo} value={estado.fecha} md={2} />
                    <RTextField type='text' name="notas" label="Notas" actualizaCampo={actualizaCampo} value={estado.notas} md={4} />
                    <Button variant="contained" color="success" sx={{ color: 'white', marginLeft: '15px'}} to="#" startIcon={<Iconify icon="eva:plus-fill" />}
                    onClick={()=>Grabar()}> Grabar </Button>
                </Grid>
                {(estado.idEstado >= 5 && estado.idEstado <= 11) &&
                <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
                    <RDateField name="fechaInicio" label="Fecha Inicio*" actualizaCampo={actualizaCampo} value={estado.fechaInicio} md={2} />
                    <RTextField type="number" name="duracion" label="Duración (Meses)*" actualizaCampo={actualizaCampo} value={estado.duracion} md={2} />
                    <RDateField name="fechaFin" label="Fecha Fin*" actualizaCampo={actualizaCampo} value={estado.fechaFin} md={2} />
                </Grid>
                }
            </form>
        </Grid>        
        {!isEmpty(estadosHist) &&
        <Grid item md={12}>
            <RTabla key={key} lista={estadosHist} campos={campos} cabecera={cabecera} acciones={acciones}/>
        </Grid>
        }
    </Grid>
  );
}
