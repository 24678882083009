
import React from 'react';
// material
import { Button, Card, CardHeader, CardContent, Divider, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert2';
// components
import Page from '../../componentes/Page';
import Iconify from '../../componentes/Iconify';
// mock
import RTabla from '../../componentes/tabla';
import CONFIG from '../../CONFIG.json';
import { buildBody, isEmpty, workWithLocalStorage, getUniqueKey, compruebaForm } from '../../auxiliares/funciones';
import { getProvincias } from '../../auxiliares/maestros';
import Formulario from './form/festivoform';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    minWidth: '750px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
// ----------------------------------------------------------------------

export default function Index(props) {
  const [lista, setLista] = React.useState([]);
  const [cargado, setCargado] = React.useState(false);
  const [key, setKey] = React.useState(getUniqueKey());
  const [provincias, setProvincias] = React.useState([]);

    const campos = ['fechaF','provincia'];    
    const cabecera = ['Fecha','Provincia'];
    const usuario = workWithLocalStorage('get','usuario',null);

    React.useEffect(() => {
        async function cargaInicial(){
            if(!cargado){
                setProvincias(await getProvincias());
                // eslint-disable-next-line react-hooks/exhaustive-deps
                const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,{}))
                fetch(`${CONFIG.api}master/festivos`,{
                        method: 'POST',
                        body: bodyJson,
                        headers: {'Content-Type': 'application/json'}
                })
                .then(res => res.json())
                .then((result) => {
                    if (result.error === 0 && !isEmpty(result.data)) {
                    setLista(result.data);
                    }
                    setKey(getUniqueKey());
                    // eslint-disable-next-line react/prop-types
                    props.showLoader(false);            
                    setCargado(true);
                })
            }
        }
        cargaInicial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[cargado]);

    const Form = {
        fecha: '',
        idProvincia: -1,
        activo: 1,
      };
    const obligatorios = ['fecha'];

    const [modalOpen, setModalOpen] = React.useState(false);
    const [festivo, setFestivo] = React.useState(Form);
    const cierraModal = () => setModalOpen(false);
    const abreModal = () => setModalOpen(true);

    const Actualiza = () =>{
        // eslint-disable-next-line react/prop-types
        props.showLoader(true); 
        // setCargado(false);
        setCargado(false)
    }

    const Borrar = (obj) =>{
        // eslint-disable-next-line react/prop-types
        props.showLoader(true); 
        const body = {
            tabla: "festivos",
            activo: 0,
            usuario: usuario.id,
            id: obj.id
        }
        const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
        fetch(`${CONFIG.api}master/activo`,{
                    method: 'PUT',
                    body: bodyJson,
                    headers: {'Content-Type': 'application/json'}
            })
            .then(res => res.json())
            .then((result) => {
            let msg ='Festivo borrado!';
                if (result.error === 0) {    
                    setLista([]);               
                    setCargado(false);
                }else{
                    msg = result.errorMessage;
                }
                Swal.fire({
                    title: msg,
                    buttons: [{label: 'Ok'}]
                })
        })
    }

    const altaFestivo = (obj) =>{
        if(obj!==null){
            setFestivo(obj);
        }else{
            setFestivo(Form);
        }
        abreModal();
    }

    const actualizaCampo = (input) =>{
        const nombre = input.target.name
        const valor = input.target.value
        const comA = festivo;
        comA[nombre] = valor
        setFestivo(comA);
    }

    const Grabar = () =>{
        const valido = compruebaForm(Form,festivo,obligatorios);
        if(valido){
            // eslint-disable-next-line react/prop-types
            props.showLoader(true); 
            const body = festivo;
            if(body.idProvincia === -1){
                body.idProvincia = null;
            }
            body.idUsuarioAlta = usuario.id;
            body.idUsuarioMod = usuario.id;
            const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
            fetch(`${CONFIG.api}master/festivo`,{
                    method: 'PUT',
                    body: bodyJson,
                    headers: {'Content-Type': 'application/json'}
            })
            .then(res => res.json())
            .then((result) => {
                let msg ='Festivo grabado!';
                if (result.error === 0) { 
                    cierraModal();  
                    setCargado(false);
                }else{
                    props.showLoader(false); 
                    msg = result.errorMessage;
                }
                Swal.fire({
                    title: msg,
                    buttons: [{label: 'Ok'}]
                })
            })
        }else{
            Swal.fire({
                title: 'Revisa los campos con *',
                buttons: [{label: 'Ok'}]
            })
        }
    }

    const acciones = [
        {
          color:'info',
          nombre:'Editar',
          icono:<i />,
          funcion: altaFestivo,
          estilo: {},
          condition: null
        },
        {
          color:'error',
          nombre:'Borrar',
          icono:<i />,
          funcion: Borrar,
          estilo: {},
          condition: null
        }
      ]
    // FIN FUNCIONES DE LOS COMERCIALES ----------------------------------------

  return (    
    <Page title="Festivos" key={lista.length}>
        <Card  sx={{ maxWidth: '100% !important'}}>
          <CardHeader action={<>
                                <Button variant="contained" color="primary" sx={{ color: 'white', marginRight: '15px'}} to="#" startIcon={<Iconify icon="eva:plus-fill" />}
                                onClick={()=>altaFestivo(null)}>
                                  Alta
                                </Button>
                                <Button variant="contained" color="success" sx={{ color: 'white'}} to="#" startIcon={<Iconify icon="eva:refresh-fill" />}
                                onClick={()=>Actualiza()}>
                                  Actualizar
                                </Button>
                              </>}
                  title="Festivos"
          />
          <Divider style={{ margin: '15px'}} />
          <CardContent>
            <RTabla key={key} lista={lista} campos={campos} rowsPerPage={25} cabecera={cabecera} acciones={acciones}/>
          </CardContent>
        </Card>
        <Modal open={modalOpen} onClose={cierraModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Card sx={style}>
                <CardHeader title={'Alta Festivo'}
                action={<>
                    <Button variant="contained" color="primary" sx={{ color: 'white', marginRight: '15px'}} to="#" startIcon={<Iconify icon="eva:edit-outline" />}
                    onClick={()=>Grabar()}>
                        Grabar
                    </Button>
                    <CloseIcon fontSize="inherit" onClick={() => cierraModal() }/>
                </>}/>
                <Divider style={{ margin: '15px'}} />
                <CardContent>
                    <Formulario showLoader={props.showLoader} festivo={festivo} provincias={provincias} actualizaCampo={actualizaCampo}/>
                </CardContent>
            </Card>
        </Modal>
    </Page>
  );
}
