import React from 'react';
// material
import {Card, CardContent, Divider, Button, CardHeader, Modal, Grid} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Swal from 'sweetalert2';
import CloseIcon from '@mui/icons-material/Close';
// components
import Page from '../../componentes/Page';
import Iconify from '../../componentes/Iconify';
// mock
import RTabla from '../../componentes/tabla';
import CONFIG from '../../CONFIG.json';
import { buildBody, isEmpty, getUniqueKey, compruebaForm, workWithLocalStorage } from '../../auxiliares/funciones';
import { getTutores } from '../../auxiliares/maestros';

import Editar from '../trabajadores/alta';
import MatForm from './form/matform';
import RTextField from "../../componentes/forms/inputs/RTextField";
import RDateField from "../../componentes/forms/inputs/RDateField";

// ----------------------------------------------------------------------
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  minWidth: '750px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function Index(props) {
    // eslint-disable-next-line camelcase
  const filtrate_pl = {inicio: '', fin: ''}
  const usuario = workWithLocalStorage('get','usuario',null);
  const [lista, setLista] = React.useState([]);
  const [tutores, setTutores] = React.useState([]);
  const [filtro, setFiltro] = React.useState(filtrate_pl);
  const [cargado, setCargado] = React.useState(false);
  const [busqText, setBusqText] = React.useState('');
  const [key, setKey] = React.useState(getUniqueKey());

    const campos = ['fechaInicio','fechaFin','proveedor','codigo','nombre','notas','proximo','estadocontrato'];
    const cabecera = ['F. Inicio','F. Fin','Proveedor','Curso','Nombre Alumno','Observaciones','Cercano','Est. Contr.'];

    React.useEffect(() => {
      async function cargaInicial(){
        if(!cargado){
          setTutores(await getTutores());
          const body = filtro;
          // eslint-disable-next-line react-hooks/exhaustive-deps
          const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
          fetch(`${CONFIG.api}tutores/matriculas`,{
                    method: 'POST',
                    body: bodyJson,
                    headers: {'Content-Type': 'application/json'}
            })
            .then(res => res.json())
            .then((result) => {
              if (result.error === 0 && !isEmpty(result.data)) {
                setLista(result.data);
              }else{
                  setLista([]);
              }
              setKey(getUniqueKey());
              // eslint-disable-next-line react/prop-types
              props.showLoader(false);            
              setCargado(true);
          })
        }
      }
    cargaInicial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[cargado]);

    const verTrabajador = (objeto) => props.setTabs({ nombre: `Editar Contrato #${objeto.idtrabajador}`, componente: Editar, params: {id: objeto.idtrabajador}, visible: true })

    const FormMat = {
      idContrato:0,
      idEspecialidad:0,
      confirmacionProveedor: '',
      codigoAulaMadre: '',
      codigoAulaAlumno: '',
      idTutor: 0,
      notas:''
    };
    const obligatorios = [];

    const [modalOpen, setModalOpen] = React.useState(false);
    const [matricula, setMatricula] = React.useState(FormMat);
    const cierraModal = () => setModalOpen(false);
    const abreModal = (obj) => {
      // console.log(obj)
      if(obj.idmatricula === null){
        const matriculaF = FormMat
        matriculaF.codigoAulaMadre = obj.aulamadre
        matriculaF.idContrato = obj.idcontrato
        matriculaF.idEspecialidad = obj.idespecialidad
        setMatricula(matriculaF)
        setModalOpen(true);
      }else{        
        props.showLoader(true); 
        const body = { id: obj.idcontrato, curso: obj.idespecialidad };
          // eslint-disable-next-line react-hooks/exhaustive-deps
          const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
          fetch(`${CONFIG.api}tutores/matricula`,{
                    method: 'POST',
                    body: bodyJson,
                    headers: {'Content-Type': 'application/json'}
            })
            .then(res => res.json())
            .then((result) => {
              // console.log(result)
              if (result.error === 0 && !isEmpty(result.data)) {
                result.data.idContrato = obj.idcontrato
                result.data.idEspecialidad = obj.idespecialidad
                setMatricula(result.data)
                setModalOpen(true);
              }
              // eslint-disable-next-line react/prop-types
              props.showLoader(false); 
          })
      }
    }

    const theme = useTheme();
    const acciones = [      
      {
        color:'primary',
        nombre:'Editar',
        icono:<Iconify icon="eva:edit-fill" style={{ fontSize: '20px' }} />,
        funcion: abreModal,
        estilo: { backgroundColor: theme.palette.gradients.primary, color: theme.palette.common.white},
        condition: null
      },
      {
        color:'info',
        nombre:'Ver',
        icono:<Iconify icon="eva:eye-fill" style={{ fontSize: '20px' }} />,
        funcion: verTrabajador,
        estilo: { backgroundColor: theme.palette.gradients.primary, color: theme.palette.common.white},
        condition: null
      }
    ]

    const actualizaCampo = (input) =>{
      const nombre = input.target.name
      const valor = input.target.value
      const comA = matricula;
      comA[nombre] = valor
      setMatricula(comA);
  }

    const actualizaFiltro = (input) =>{
      const nombre = input.target.name
      const valor = input.target.value
      filtro[nombre] = valor
      setFiltro(filtro);
    }

    const actualizaBusc = (valor) =>{
        setBusqText(valor);
    }

    const GrabarMat = () =>{
      const valido = compruebaForm(FormMat,matricula,obligatorios);
      if(valido){
          // eslint-disable-next-line react/prop-types
          props.showLoader(true); 
          const body = matricula;
          console.log(body)
          body.idUsuarioAlta = usuario.id;
          body.idUsuarioMod = usuario.id;
          const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
          fetch(`${CONFIG.api}tutores/matricula`,{
                  method: 'PUT',
                  body: bodyJson,
                  headers: {'Content-Type': 'application/json'}
          })
          .then(res => res.json())
          .then((result) => {
              let msg ='Información grabada!';
              if (result.error === 0) { 
                  cierraModal();  
                  setCargado(false);
              }else{
                  props.showLoader(false); 
                  msg = result.errorMessage;
              }
              Swal.fire({
                  title: msg,
                  buttons: [{label: 'Ok'}]
              })
          })
      }else{
          Swal.fire({
              title: 'Revisa los campos con *',
              buttons: [{label: 'Ok'}]
          })
      }
  }

    const Buscar = (reset) =>{
        console.log(filtro)
        if(reset){
            setFiltro(filtrate_pl);
        }
        props.showLoader(true);
        setCargado(false);
    }

  return (    
    <Page title="Matriculaciones" key={lista.length} sx={{ width: '100% !important'}}>
        <Card  sx={{ maxWidth: '100% !important'}}>
            <CardHeader title="Matriculaciones" action={<Grid container>
                <RDateField type="text" name="inicio" label="Desde Inicio" actualizaCampo={actualizaFiltro} value={filtro.inicio} md={3} />
                <RDateField type="text" name="fin" label="Hasta Inicio" actualizaCampo={actualizaFiltro} value={filtro.fin} md={3} />
                <Button variant="contained" color="success" sx={{ color: 'white', marginRight: '15px', marginLeft: '15px'}} to="#"
                onClick={()=>Buscar(false)}>
                    Filtrar
                </Button>
                <Button variant="contained" color="error" sx={{ color: 'white', marginRight: '15px'}} to="#"
                onClick={()=>Buscar(true)}>
                    Borrar Filtro
                </Button>
            </Grid>}/>
          <Divider style={{ margin: '15px'}} />
          <CardContent>
            <RTabla key={key} lista={lista} campos={campos} cabecera={cabecera} acciones={acciones} busqText={busqText} actualizaBusc={actualizaBusc}/>
          </CardContent>
        </Card>
        <Modal open={modalOpen} onClose={cierraModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Card sx={style}>
                <CardHeader title="Datos de la matrícula"
                action={<>
                    <Button variant="contained" color="primary" sx={{ color: 'white', marginRight: '15px'}} to="#" startIcon={<Iconify icon="eva:edit-outline" />}
                    onClick={()=>GrabarMat()}>
                        Grabar
                    </Button>
                    <CloseIcon fontSize="inherit" onClick={() => cierraModal() }/>
                </>}/>
                <Divider style={{ margin: '15px'}} />
                <CardContent>
                    <MatForm showLoader={props.showLoader} tutores={tutores} matricula={matricula} actualizaCampo={actualizaCampo}/>
                </CardContent>
            </Card>
        </Modal>
    </Page>
  );
}
