import React from 'react';
// material
import { Card, CardContent, Divider, Button, CardHeader } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
import Page from '../../componentes/Page';
import Iconify from '../../componentes/Iconify';
// mock
import RTabla from '../../componentes/tabla';
import CONFIG from '../../CONFIG.json';
import { buildBody, isEmpty, getUniqueKey } from '../../auxiliares/funciones';

import Editar from '../contratos/alta';

// ----------------------------------------------------------------------

export default function Index(props) {
  const [lista, setLista] = React.useState([]);
  const [cargado, setCargado] = React.useState(false);
  const [key, setKey] = React.useState(getUniqueKey());

    const campos = ['id','empresa','trabajador','fechaAlta','observaciones','estadocontrato','fechaFin','motivo'];    
    const cabecera = ['ID','Empresa','Trabajador','Alta Contrato','Notas','Estado','Fin Ausencia','Motivo'];

    React.useEffect(() => {
      if(!cargado){
        const body = {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
        fetch(`${CONFIG.api}listados/ausencias`,{
                  method: 'POST',
                  body: bodyJson,
                  headers: {'Content-Type': 'application/json'}
          })
          .then(res => res.json())
          .then((result) => {
            if (result.error === 0 && !isEmpty(result.data)) {
              setLista(result.data);
            }
            setKey(getUniqueKey());
            // eslint-disable-next-line react/prop-types
            props.showLoader(false);            
            setCargado(true);
        })
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[cargado]);

    const verContrato = (objeto) => props.setTabs({ nombre: `Editar Contrato #${objeto.id}`, componente: Editar, params: {id: objeto.id}, visible: true })

    const Actualiza = () =>{
      // eslint-disable-next-line react/prop-types
      props.showLoader(true); 
      // setCargado(false);
      setCargado(false)
    }

    const theme = useTheme();
    const acciones = [
      {
        color:undefined,
        nombre:'Ver',
        icono:<Iconify icon="eva:edit-fill" style={{ fontSize: '20px' }} />,
        funcion: verContrato,
        estilo: { backgroundColor: theme.palette.gradients.primary, color: theme.palette.common.white},
        condition: null
      }
    ]

  return (    
    <Page title="Contratos en ausencias" key={lista.length} sx={{ width: '100% !important'}}>
        <Card  sx={{ maxWidth: '100% !important'}}>
            <CardHeader action={<>
                                <Button variant="contained" color="success" sx={{ color: 'white'}} to="#" startIcon={<Iconify icon="eva:refresh-fill" />}
                                onClick={()=>Actualiza()}>
                                  Actualizar
                                </Button>
                              </>}
                  title="Contratos en ausencias"
          />
          <Divider style={{ margin: '15px'}} />
          <CardContent>
            <RTabla key={key} lista={lista} campos={campos} cabecera={cabecera} acciones={acciones}/>
          </CardContent>
        </Card>
    </Page>
  );
}
