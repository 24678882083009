import React from 'react';
// @mui
import { Grid } from '@mui/material';
// components
import RSelect from '../../../componentes/forms/inputs/RSelect';
import RDateField from '../../../componentes/forms/inputs/RDateField';

// ----------------------------------------------------------------------

export default function FestForm({ showLoader, actualizaCampo, festivo, provincias }) {   
  React.useEffect(() => {
    showLoader(false); 
    // eslint-disable-next-line
  },[]);

  if(provincias[0].id !== -1){
    provincias.unshift({id: -1, nombre: 'Festivo Nacional'});
  }

  return (
    <form style={{ width: '100%'}}>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <RDateField name="fecha" label="Fecha*" actualizaCampo={actualizaCampo} value={festivo.fecha} md={4} />
        <RSelect name="idProvincia" label="Provincia*" actualizaCampo={actualizaCampo} value={festivo.idProvincia} md={4}
            values={provincias} searchable={1}/>
      </Grid>
    </form>
  );
}
