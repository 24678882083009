import CONFIG from '../CONFIG.json';
import { buildBody, isEmpty } from './funciones';

// ----------------------------------------------------------------------

export const getProvincias = async() => {
    const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,{}))
    const res = await fetch(`${CONFIG.api}master/provincias`,{
        method: 'POST',
        body: bodyJson,
        headers: {'Content-Type': 'application/json'}
    });
    const result = await res.json()
    if (result.error === 0 && !isEmpty(result.data)) {
        return result.data;
    }
    return [];
}

export const getRedesComerciales = async() => {
    const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,{}))
    const res = await fetch(`${CONFIG.api}master/redescomerciales`,{
        method: 'POST',
        body: bodyJson,
        headers: {'Content-Type': 'application/json'}
    });
    const result = await res.json()
    if (result.error === 0 && !isEmpty(result.data)) {
        return result.data;
    }
    return [];
}

export const getComerciales = async() => {
    const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,{}))
    const res = await fetch(`${CONFIG.api}master/comerciales`,{
        method: 'POST',
        body: bodyJson,
        headers: {'Content-Type': 'application/json'}
    });
    const result = await res.json()
    if (result.error === 0 && !isEmpty(result.data)) {
        return result.data;
    }
    return [];
}

export const getAsesorias = async() => {
    const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,{}))
    const res = await fetch(`${CONFIG.api}master/asesorias`,{
        method: 'POST',
        body: bodyJson,
        headers: {'Content-Type': 'application/json'}
    });
    const result = await res.json()
    if (result.error === 0 && !isEmpty(result.data)) {
        return result.data;
    }
    return [];
}

export const getEmpresas = async() => {
    const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,{}))
    const res = await fetch(`${CONFIG.api}master/empresas`,{
        method: 'POST',
        body: bodyJson,
        headers: {'Content-Type': 'application/json'}
    });
    const result = await res.json()
    if (result.error === 0 && !isEmpty(result.data)) {
        return result.data;
    }
    return [];
}

export const getTrabajadores = async() => {
    const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,{}))
    const res = await fetch(`${CONFIG.api}master/trabajadores`,{
        method: 'POST',
        body: bodyJson,
        headers: {'Content-Type': 'application/json'}
    });
    const result = await res.json()
    if (result.error === 0 && !isEmpty(result.data)) {
        return result.data;
    }
    return [];
}

export const getFamProfesionales = async() => {
    const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,{}))
    const res = await fetch(`${CONFIG.api}master/familiasprof`,{
        method: 'POST',
        body: bodyJson,
        headers: {'Content-Type': 'application/json'}
    });
    const result = await res.json()
    if (result.error === 0 && !isEmpty(result.data)) {
        return result.data;
    }
    return [];
}

export const getOcupaciones = async() => {
    const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,{}))
    const res = await fetch(`${CONFIG.api}master/ocupaciones`,{
        method: 'POST',
        body: bodyJson,
        headers: {'Content-Type': 'application/json'}
    });
    const result = await res.json()
    if (result.error === 0 && !isEmpty(result.data)) {
        return result.data;
    }
    return [];
}

export const getCatalogoEsp = async() => {
    const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,{}))
    const res = await fetch(`${CONFIG.api}master/catalogoesp`,{
        method: 'POST',
        body: bodyJson,
        headers: {'Content-Type': 'application/json'}
    });
    const result = await res.json()
    if (result.error === 0 && !isEmpty(result.data)) {
        return result.data;
    }
    return [];
}

export const getContratosEstados = async() => {
    const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,{}))
    const res = await fetch(`${CONFIG.api}master/contratosestados`,{
        method: 'POST',
        body: bodyJson,
        headers: {'Content-Type': 'application/json'}
    });
    const result = await res.json()
    if (result.error === 0 && !isEmpty(result.data)) {
        return result.data;
    }
    return [];
}

export const getCentrosFormacion = async() => {
    const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,{}))
    const res = await fetch(`${CONFIG.api}master/centrosformacion`,{
        method: 'POST',
        body: bodyJson,
        headers: {'Content-Type': 'application/json'}
    });
    const result = await res.json()
    if (result.error === 0 && !isEmpty(result.data)) {
        return result.data;
    }
    return [];
}

export const getConvenios = async() => {
    const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,{}))
    const res = await fetch(`${CONFIG.api}master/convenios`,{
        method: 'POST',
        body: bodyJson,
        headers: {'Content-Type': 'application/json'}
    });
    const result = await res.json()
    if (result.error === 0 && !isEmpty(result.data)) {
        return result.data;
    }
    return [];
}

export const getTutores = async() => {
    const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,{}))
    const res = await fetch(`${CONFIG.api}tutores/listado`,{
        method: 'POST',
        body: bodyJson,
        headers: {'Content-Type': 'application/json'}
    });
    const result = await res.json()
    if (result.error === 0 && !isEmpty(result.data)) {
        return result.data;
    } 
    return {};
}