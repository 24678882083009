import React from 'react';
// @mui
import { Grid } from '@mui/material';
// components
import RTextField from '../../../componentes/forms/inputs/RTextField';

// ----------------------------------------------------------------------

export default function ComForm({ showLoader, actualizaCampo, comercial }) {   
  React.useEffect(() => {
    showLoader(false); 
    // eslint-disable-next-line
  },[]);

  return (
    <form style={{ width: '100%'}}>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <RTextField type="text" name="nombre" label="Nombre*" actualizaCampo={actualizaCampo} value={comercial.nombre} md={9} />
        <RTextField type="text" name="telefono" label="Teléfono*" actualizaCampo={actualizaCampo} value={comercial.telefono} md={3} />
      </Grid>
    </form>
  );
}
